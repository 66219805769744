import { DialogActions, DialogContent, makeStyles, Typography } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, NumberTypeProvider, PaginationLayout, Row, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { NumberFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/NumberFormatter"
import { DateItem, Form, Placeholder, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { ApproveCallbackViewAction } from "../../../components/Action/ApproveCallbackViewAction"
import { RejectCallbackViewAction } from "../../../components/Action/RejectCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useApproveRequest, useRejectRequest } from "../../../services/order/apis/CCRequestApi"
import { RequestStatus } from "../../../services/order/enums/RequestStatus"
import { RequestDetailHead, RequestPartsDetail } from "../../../services/order/models/CCRequestDetailResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { formatDateRange } from "../../../utils/formatDateRange"
import { applicationActions } from "../../Application/applicationSlice"

interface ORS011PcUiProps {
    roSoId: number,
    headerInfo: RequestDetailHead,
    partsList: RequestPartsDetail[],
    search: () => void
}

export const ORS011FcPcUi = (props: ORS011PcUiProps) => {
    const { roSoId, headerInfo, partsList, search } = props
    const intl = useIntl()
    const cbdsType = useGetCompanyType()
    const actions = cbdsType === CbdsType.SUPP && headerInfo.requestStatus === RequestStatus.NEW ? [<RejectAction headerInfo={headerInfo} />, <ApproveAction headerInfo={headerInfo} search={search} />] : []
    return (
        <View actions={actions}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title="Request Detail Information"
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <DetailHeaderInfo roSoId={roSoId} headerInfo={headerInfo} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title="Parts Detail Information"
                    subtitle={intl.formatMessage({ id: 'contactInfoSub' })}
                />
                <SectionCardContent>
                    <DetailPartsInfo headerInfo={headerInfo} partsDetailList={partsList} />
                </SectionCardContent>
            </SectionCard>
        </View>
    )
}

interface Step1Props {
    roSoId: number,
    headerInfo: RequestDetailHead,
}
const DetailHeaderInfo = memo((props: Step1Props) => {
    const intl = useIntl()
    const { headerInfo } = props
    const cbdsType = useGetCompanyType()

    return (
        <Form readonly data={headerInfo} labelDisplay="block" helperDisplay="tooltip" >
            <StringItem field="requestNo" label={intl.formatMessage({ id: 'field.requestNo' })} />
            {cbdsType === CbdsType.CUST && <StringItem field="orderNo" label={intl.formatMessage({ id: 'field.customerOrderNo' })} />}
            {cbdsType === CbdsType.BU && <StringItem field="orderNo" label={intl.formatMessage({ id: 'purchaseOrderNo' })} />}
            {cbdsType === CbdsType.SUPP && <StringItem field="orderNo" label={intl.formatMessage({ id: 'salesOrderNo' })} />}
            <CodeItem field="orderType" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />

            <CodeItem field="requestType" label={intl.formatMessage({ id: 'field.requestType' })} code={CodeCategory.RequestType} />
            {cbdsType === CbdsType.CUST && <StringItem field="customerRefNo" label={intl.formatMessage({ id: 'field.customerRefNo' })} />}
            {cbdsType !== CbdsType.CUST && <StringItem field="seller" label={intl.formatMessage({ id: 'seller' })} />}
            <CodeItem field="orderFrequency" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />

            <CodeItem field="requestStatus" label={intl.formatMessage({ id: 'requestStatus' })} code={CodeCategory.RequestStatus} />
            <StringItem field="buyer" label={intl.formatMessage({ id: 'buyer' })} />
            {cbdsType === CbdsType.CUST && <CodeItem field="status" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.CoStatus} />}
            {cbdsType === CbdsType.BU && <CodeItem field="status" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.PoStatus} />}
            {cbdsType === CbdsType.SUPP && <CodeItem field="status" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.SoStatus} />}

            <StringItem field="requestCreatedBy" label={intl.formatMessage({ id: 'requestCreatedBy' })} />
            {cbdsType === CbdsType.CUST && <StringItem field="contractNo" label={intl.formatMessage({ id: 'customerContractNo' })} />}
            {cbdsType === CbdsType.BU && <StringItem field="contractNo" label={intl.formatMessage({ id: 'poContractNo' })} />}
            {cbdsType === CbdsType.SUPP && <StringItem field="contractNo" label={intl.formatMessage({ id: 'soContractNo' })} />}
            <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />

            <DateItem field="requestCreatedDate" label={intl.formatMessage({ id: 'requestCreatedDate' })} />
            <StringItem field="contractRouteNo" label={intl.formatMessage({ id: 'contractRouteNo' })} />
            <StringItem field="rejectRemark" label={intl.formatMessage({ id: 'field.rejectReason' })} />

            <StringItem field="requestHandledBy" label={intl.formatMessage({ id: 'requestHandledBy' })} />
            <StringItem field="submittedBy" label={intl.formatMessage({ id: 'field.submittedBy' })} />
            <Placeholder />

            <DateItem field="requestHandledDate" label={intl.formatMessage({ id: 'requestHandledDate' })} />
            <DateItem field="submittedDate" label={intl.formatMessage({ id: 'field.submittedDate' })} />
            <Placeholder />
        </Form>
    )
})

interface Step2Props {
    headerInfo: RequestDetailHead,
    partsDetailList: RequestPartsDetail[],
}
const DetailPartsInfo = memo((props: Step2Props) => {
    const { headerInfo, partsDetailList, } = props
    const forecastDates = headerInfo.forecastDates
    const forecastNum = headerInfo.forecastDates?.length
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])

    const cbdsType = useGetCompanyType()
    const getFlctuationFc = useCallback((index: number) => {
        return (row: Row) => {
            const forecastQty = row.forecastQtys && row.forecastQtys[index] && row.forecastQtys[index].length > 1 ? row.forecastQtys[index][1] : null
            const lastForecastQty = row.lastForecastQtys[index]
            if (forecastQty !== null && forecastQty !== undefined && lastForecastQty) {
                return (forecastQty - lastForecastQty) / lastForecastQty
            }
            return null
        }
    }, [])

    const columns = useMemo(() => {
        const fixedColumns = getDefaultColumns(intl, cbdsType)

        //process forecast column
        const getFcCategories = (index: number) => {
            const titlesDate = forecastDates ? forecastDates[index] : undefined
            return titlesDate ? [{ key: `fc${index}`, value: formatDateRange(intl, titlesDate[0], titlesDate[1]) }] : undefined
        }
        const fcColumns = Arrays.range(0, forecastNum ?? 0).flatMap(index => index < (forecastNum ?? 0) - 1 ? [
            { field: `oldForecast${index + 1}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'oldForecast' })} ${index + 1}`, categories: getFcCategories(index), width: 200, getCellValue: (row: Row) => row['forecastQtys'][index][0] },
            { field: `newForecast${index + 1}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'newForecast' })} ${index + 1}`, categories: getFcCategories(index), width: 200, getCellValue: (row: Row) => row['forecastQtys'][index][1] },
            { field: 'lastOrderForecast' + (index + 2), dataTypeName: 'number', title: `${intl.formatMessage({ id: 'lastOrderForecast' })} ${(index + 2)}`, categories: getFcCategories(index), width: 230, getCellValue: (row: Row) => row['lastForecastQtys'][index] },
            { field: 'fluctuation' + index + 1, dataTypeName: 'fcFluctuation', title: `${intl.formatMessage({ id: 'fluctuation' })} ${index + 1}`, categories: getFcCategories(index), width: 150, getCellValue: getFlctuationFc(index) },
            { field: `fcFluctuationReason${index + 1}`, dataTypeName: 'string', title: `${intl.formatMessage({ id: 'reasonForFluctuation' })} ${index + 1}`, categories: getFcCategories(index), width: 250, getCellValue: (row: Row) => row['fcFluctuationReasons'][index] },
        ] : [
            { field: `oldForecast${index + 1}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'oldForecast' })} ${index + 1}`, categories: getFcCategories(index), width: 200, getCellValue: (row: Row) => row['forecastQtys'][index][0] },
            { field: `newForecast${index + 1}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'newForecast' })} ${index + 1}`, categories: getFcCategories(index), width: 200, getCellValue: (row: Row) => row['forecastQtys'][index][1] },
        ])
        // all columns
        return [...fixedColumns, ...fcColumns]
    }, [cbdsType, forecastDates, forecastNum, getFlctuationFc, intl])

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    if (!partsDetailList) {
        return <></>
    }

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <FluctuationTypeProvider />
            <FcFluctuationTypeProvider />
            <Data rows={partsDetailList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const getDefaultColumns = (intl: IntlShape, cbdsType: string | number | null) => {
    if (cbdsType === CbdsType.CUST) {
        return [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 250 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 150 },
            { field: 'supplier', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150 },
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
            { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'oldFirm', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), width: 150 }
        ]
    } else if (cbdsType === CbdsType.BU) {
        return [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'poPartsNo' }), width: 250 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 150 },
            { field: 'supplier', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150 },
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
            { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'oldFirm', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), width: 150 }
        ]
    } else if (cbdsType === CbdsType.SUPP) {
        return [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'soPartsNo' }), width: 250 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 150 },
            { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150 },
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
            { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'oldFirm', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), width: 150 }
        ]
    } else {
        return []
    }

}

const FluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRate = row.fluctuationRate
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRate ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRate === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FluctuationFormatter} />
}

const FcFluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRate = row.fcFluctuationRate
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRate ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRate === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FcFluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fcFluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FcFluctuationFormatter} />
}

const ApproveAction = ({ headerInfo, search }: { headerInfo: RequestDetailHead, search: () => void }) => {
    const approveMethod = useApproveRequest()
    const lineKey = headerInfo.roSoId + "#" + headerInfo.dataFlag
    const approve = useCallback(() => {
        approveMethod({ lineKeys: [lineKey] }, { serialized: true }).then(() => {
            search()
        })
    }, [approveMethod, lineKey, search])
    return <ApproveCallbackViewAction access="ORDER.ORS011.APPROVE" callback={approve} />
}

interface RejectRemark {
    remark?: string
}
const RejectAction = ({ headerInfo }: { headerInfo: RequestDetailHead }) => {
    const rejectRequest = useRejectRequest()
    const lineKey = headerInfo.roSoId + "#" + headerInfo.dataFlag
    const intl = useIntl()
    const navigate = useNavigate()
    const [open, setOpen] = useState<boolean>(false)
    const [remark, setRemark] = useState<RejectRemark>({})
    const dispatch = useDispatch()
    const title = useMemo(() => intl.formatMessage({ id: 'reject' }), [intl])
    const functionStore = useFunctionStore()
    const callReject = useCallback(() => {
        setOpen(true)
        setRemark({})
    }, [])
    const reject = useCallback(() => {
        const functionId = functionStore.register(() => {
            // do reject
            rejectRequest({ lineKeys: [lineKey], rejectRemark: remark.remark }, { serialized: true }).then(() => {
                navigate('/cc')
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
        // open pdialog
        setOpen(false)
    }, [dispatch, functionStore, lineKey, navigate, rejectRequest, remark.remark, title])
    return <Access access="ORDER.ORS010.REJECT">
        <RejectCallbackViewAction callback={callReject} />
        <CardDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="inputRejectReason" />
            </DialogHeader>
            <DialogContent>
                <Form data={remark} setData={setRemark} labelDisplay="block" helperDisplay="tooltip" minWidth={500} maxWidth={500} columnCount={1}>
                    <StringItem field="remark" labelDisplay='inline' labelWidth={120} label={intl.formatMessage({ id: 'field.rejectReason' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="reject" />} callback={reject} />
            </DialogActions>
        </CardDialog>
    </Access>
}

const useStyles = makeStyles(theme => ({
    fulcuationCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))
