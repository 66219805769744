import { DialogActions, DialogContent, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core"
import { Edit, Refresh } from "@material-ui/icons"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, NumberTypeProvider, PaginationLayout, Row, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { DataGridCategoryProps } from "@rithe/data-grid/dist/components/basic/DataGridCategory"
import { NumberFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/NumberFormatter"
import { Break, DateItem, Form, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import moment from "moment"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { ApproveCallbackViewAction } from "../../../components/Action/ApproveCallbackViewAction"
import { ProposeNewCallbackViewAction } from "../../../components/Action/ProposeNewCallbackViewAction"
import { RejectCallbackViewAction } from "../../../components/Action/RejectCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useApproveRequest, useProposeNewConfirm, useRefreshInboundInfo, useRejectRequest } from "../../../services/order/apis/CCRequestApi"
import { RequestStatus } from "../../../services/order/enums/RequestStatus"
import { RequestType } from "../../../services/order/enums/RequestType"
import { RequestDetailHead, RequestPartsDetail } from "../../../services/order/models/CCRequestDetailResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { formatDateRange } from "../../../utils/formatDateRange"
import { applicationActions } from "../../Application/applicationSlice"

interface ORS011PcUiProps {
    roSoId: number,
    headerInfo: RequestDetailHead,
    partsList: RequestPartsDetail[],
    setPartsList: React.Dispatch<React.SetStateAction<RequestPartsDetail[]>>,
    search: () => void
}

export const ORS011PcUi = (props: ORS011PcUiProps) => {
    const { roSoId, headerInfo, partsList, setPartsList, search } = props
    const intl = useIntl()
    const cbdsType = useGetCompanyType()
    const actionCondition1 = cbdsType === CbdsType.SUPP && (headerInfo.dataFlag === 0 || headerInfo.dataFlag === 1) && headerInfo.requestStatus === RequestStatus.NEW
    const actionCondition2 = cbdsType === CbdsType.CUST && headerInfo.dataFlag === 2 && headerInfo.requestStatus === RequestStatus.NEW
    const actionCondition3 = cbdsType === CbdsType.BU && headerInfo.dataFlag === 3 && headerInfo.requestStatus === RequestStatus.NEW
    const unShowProposeNew = cbdsType === CbdsType.SUPP || RequestType.ORDER_CHANGE !== headerInfo.requestType
    const actions = actionCondition1 || actionCondition2 || actionCondition3 ? [
        (unShowProposeNew ? <></> : <ProposeNewAction headerInfo={headerInfo} partsList={partsList} proposeNew={true} search={search} />),
        <RejectAction headerInfo={headerInfo} />, <ApproveAction headerInfo={headerInfo} search={search} />] : []

    return (
        <View actions={actions}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'requestDetail' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <DetailHeaderInfo roSoId={roSoId} headerInfo={headerInfo} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'partsDetail' })}
                    subtitle={intl.formatMessage({ id: 'contactInfoSub' })}
                />
                <SectionCardContent>
                    <DetailPartsInfo headerInfo={headerInfo} partsDetailList={partsList} proposeNew={false} setPartsDetailList={setPartsList} search={search} />
                </SectionCardContent>
            </SectionCard>
        </View>
    )
}

interface Step1Props {
    roSoId: number,
    headerInfo: RequestDetailHead,
}
const DetailHeaderInfo = memo((props: Step1Props) => {
    const intl = useIntl()
    const { headerInfo } = props
    const cbdsType = useGetCompanyType()

    return (
        <Form readonly data={headerInfo} labelDisplay="block" helperDisplay="tooltip" >
            <StringItem field="requestNo" label={intl.formatMessage({ id: 'field.requestNo' })} />
            {cbdsType === CbdsType.CUST && <StringItem field="orderNo" label={intl.formatMessage({ id: 'field.customerOrderNo' })} />}
            {cbdsType === CbdsType.BU && (headerInfo.dataFlag === 0 || headerInfo.dataFlag === 1 || headerInfo.dataFlag === 3) && <StringItem field="orderNo" label={intl.formatMessage({ id: 'purchaseOrderNo' })} />}
            {cbdsType === CbdsType.BU && headerInfo.dataFlag === 2 && <StringItem field="orderNo" label={intl.formatMessage({ id: 'salesOrderNo' })} />}
            {cbdsType === CbdsType.SUPP && <StringItem field="orderNo" label={intl.formatMessage({ id: 'salesOrderNo' })} />}
            <CodeItem field="orderType" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />

            <CodeItem field="requestType" label={intl.formatMessage({ id: 'field.requestType' })} code={CodeCategory.RequestType} />
            {cbdsType === CbdsType.CUST && <StringItem field="customerRefNo" label={intl.formatMessage({ id: 'field.customerRefNo' })} />}
            {cbdsType !== CbdsType.CUST && <StringItem field="seller" label={intl.formatMessage({ id: 'seller' })} />}
            <CodeItem field="orderFrequency" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />

            <CodeItem field="requestStatus" label={intl.formatMessage({ id: 'requestStatus' })} code={CodeCategory.RequestStatus} />
            <StringItem field="buyer" label={intl.formatMessage({ id: 'buyer' })} />
            {cbdsType === CbdsType.CUST && <CodeItem field="orderStatus" label={intl.formatMessage({ id: 'field.orderStatus' })} code={CodeCategory.CoStatus} />}
            {cbdsType === CbdsType.BU && (headerInfo.dataFlag === 0 || headerInfo.dataFlag === 1 || headerInfo.dataFlag === 3) && <CodeItem field="orderStatus" label={intl.formatMessage({ id: 'field.orderStatus' })} code={CodeCategory.PoStatus} />}
            {cbdsType === CbdsType.BU && headerInfo.dataFlag === 2 && <CodeItem field="orderStatus" label={intl.formatMessage({ id: 'field.orderStatus' })} code={CodeCategory.SoStatus} />}
            {cbdsType === CbdsType.SUPP && <CodeItem field="orderStatus" label={intl.formatMessage({ id: 'field.orderStatus' })} code={CodeCategory.SoStatus} />}

            <StringItem field="requestCreatedBy" label={intl.formatMessage({ id: 'requestCreatedBy' })} />
            {cbdsType === CbdsType.CUST && <StringItem field="contractNo" label={intl.formatMessage({ id: 'customerContractNo' })} />}
            {cbdsType === CbdsType.BU && (headerInfo.dataFlag === 0 || headerInfo.dataFlag === 1 || headerInfo.dataFlag === 3) && <StringItem field="contractNo" label={intl.formatMessage({ id: 'poContractNo' })} />}
            {cbdsType === CbdsType.BU && headerInfo.dataFlag === 2 && <StringItem field="contractNo" label={intl.formatMessage({ id: 'soContractNo' })} />}
            {cbdsType === CbdsType.SUPP && <StringItem field="contractNo" label={intl.formatMessage({ id: 'soContractNo' })} />}
            <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />

            <DateItem field="requestCreatedDate" label={intl.formatMessage({ id: 'requestCreatedDate' })} />
            <StringItem field="contractRouteNo" label={intl.formatMessage({ id: 'contractRouteNo' })} />
            <StringItem field="rejectRemark" label={intl.formatMessage({ id: 'field.rejectReason' })} />

            <StringItem field="requestHandledBy" label={intl.formatMessage({ id: 'requestHandledBy' })} />
            <StringItem field="submittedBy" label={intl.formatMessage({ id: 'field.submittedBy' })} />
            <Break />

            <DateItem field="requestHandledDate" label={intl.formatMessage({ id: 'requestHandledDate' })} />
            <DateItem field="submittedDate" label={intl.formatMessage({ id: 'field.submittedDate' })} />
            <Break />
        </Form>
    )
})

interface Step2Props {
    headerInfo: RequestDetailHead,
    partsDetailList: RequestPartsDetail[],
    proposeNew: boolean,
    setPartsDetailList: React.Dispatch<React.SetStateAction<RequestPartsDetail[]>>,
    search: () => void
}
const DetailPartsInfo = memo((props: Step2Props) => {
    const { headerInfo, partsDetailList, setPartsDetailList, proposeNew } = props
    const requstType = headerInfo.requestType
    const isAcknowledger = headerInfo.isAcknowledger
    const dataFlag = headerInfo.dataFlag
    const showSuppOutboundFlag = headerInfo.showSuppOutboundFlag
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])

    const cbdsType = useGetCompanyType()

    const planColumnsName = useMemo(() => {
        return ((isAcknowledger === 1 && cbdsType !== CbdsType.SUPP) || (dataFlag === 0 || dataFlag === 1)) ? 'InboundDate' : 'OutboundDate'
    }, [cbdsType, isAcknowledger, dataFlag])

    const oldPlanDates = useMemo(() => {
        return partsDetailList ? Arrays.distinct(partsDetailList.flatMap(parts => parts.oldCrdQtys!).map(plan => plan?.drDate?.getTime())).sort().map(m => new Date(m)) : []
    }, [partsDetailList])

    const newPlanDates = useMemo(() => {
        return partsDetailList ? Arrays.distinct(partsDetailList.flatMap(parts => parts.newCrdQtys!).map(plan => plan?.drDate?.getTime())).sort().map(m => new Date(m)) : []
    }, [partsDetailList])

    const getOldPlanQty = useCallback((planDate: Date | null) => (row: any) => {
        if (row.oldCrdQtys && planDate) {
            const plans = row.oldCrdQtys.filter((f: any) => f.drDate?.getTime() === planDate?.getTime())
            if (plans && plans.length > 0) {
                return plans[0].drQty
            }
        }
        return null
    }, [])

    const getNewPlanQty = useCallback((planDate: Date | null) => (row: any) => {
        if (row.newCrdQtys && planDate) {
            const plans = row.newCrdQtys.filter((f: any) => f.drDate?.getTime() === planDate?.getTime())
            if (plans && plans.length > 0) {
                return plans[0].drQty
            }
        }
        return null
    }, [])

    // supp outbound dr 
    const oldSuppOutboundPlanDates = useMemo(() => {
        return (partsDetailList && showSuppOutboundFlag === 1) ? Arrays.distinct(partsDetailList.flatMap(parts => parts.oldSuppOutboundDrQtys!).map(plan => plan?.drDate?.getTime())).sort().map(m => new Date(m)) : []
    }, [partsDetailList, showSuppOutboundFlag])

    const newSuppOutboundPlanDates = useMemo(() => {
        return (partsDetailList && showSuppOutboundFlag === 1) ? Arrays.distinct(partsDetailList.flatMap(parts => parts.newSuppOutboundDrQtys!).map(plan => plan?.drDate?.getTime())).sort().map(m => new Date(m)) : []
    }, [partsDetailList, showSuppOutboundFlag])

    const getOldOutboundPlanQty = useCallback((planDate: Date | null) => (row: any) => {
        if (row.oldSuppOutboundDrQtys && planDate) {
            const plans = row.oldSuppOutboundDrQtys.filter((f: any) => f.drDate?.getTime() === planDate?.getTime())
            if (plans && plans.length > 0) {
                return plans[0].drQty
            }
        }
        return null
    }, [])

    const getNewOutboundPlanQty = useCallback((planDate: Date | null) => (row: any) => {
        if (row.newSuppOutboundDrQtys && planDate) {
            const plans = row.newSuppOutboundDrQtys.filter((f: any) => f.drDate?.getTime() === planDate?.getTime())
            if (plans && plans.length > 0) {
                return plans[0].drQty
            }
        }
        return null
    }, [])

    const setNewOutboundPlanQty = useCallback((planDate: Date | null) => (row: Row, value: any) => {
        // do fiter
        if (row.newSuppOutboundDrQtys?.some((plan: any) => (planDate && plan.drDate.getTime() === planDate.getTime()))) {
            return ({ ...row, newSuppOutboundDrQtys: row.newSuppOutboundDrQtys?.map((plan: any) => (planDate && plan.drDate.getTime() === planDate.getTime()) ? { ...plan, drQty: value } : plan) })
        } else {
            const newSuppOutboundDrQtys = row.newSuppOutboundDrQtys
            newSuppOutboundDrQtys.push({
                drDate: planDate,
                drQty: value,
            })
            return ({ ...row, newSuppOutboundDrQtys: newSuppOutboundDrQtys })
        }
    }, [])

    const getFlctuationFirm = useCallback((row: Row) => (row.newFirm != null && row.lastOrderForecast1) ? (row.newFirm - row.lastOrderForecast1) / row.lastOrderForecast1 : null, [])
    const columns = useMemo(() => {
        const fixedColumns = getDefaultColumns(intl, cbdsType, headerInfo, proposeNew)

        // firm columns 
        const firmCategories = [{ key: 'firm', value: formatDateRange(intl, headerInfo.firmDateStart, headerInfo.firmDateEnd) }]
        const firmColumns = proposeNew ?
            requstType === RequestType.FC_CHANGE ? [
                { field: 'oldFirm', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), width: 150 },
            ] : [
                { field: 'oldFirm', dataTypeName: 'number', title: intl.formatMessage({ id: 'oldFirm' }), categories: firmCategories, width: 140 },
                { field: 'newFirm', dataTypeName: 'number', title: intl.formatMessage({ id: 'newFirm' }), categories: firmCategories, width: 140 },
            ] :
            requstType === RequestType.FC_CHANGE ? [
                { field: 'oldFirm', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), width: 150 },
            ] : [
                { field: 'oldFirm', dataTypeName: 'number', title: intl.formatMessage({ id: 'oldFirm' }), categories: firmCategories, width: 150 },
                { field: 'newFirm', dataTypeName: 'number', title: intl.formatMessage({ id: 'newFirm' }), categories: firmCategories, width: 150 },
                { field: 'lastOrderForecast1', dataTypeName: 'number', title: intl.formatMessage({ id: 'lastOrderForecast' }) + " 1", categories: firmCategories, width: 230 },
                { field: 'flctuationFirm', dataTypeName: 'fluctuation', title: intl.formatMessage({ id: 'fluctuation' }), categories: firmCategories, width: 150, getCellValue: getFlctuationFirm },
                { field: 'fluctuationReason', dataTypeName: 'string', title: intl.formatMessage({ id: 'reasonForFluctuation' }), categories: firmCategories, width: 200 },
            ]

        // inbound data column
        const oldOutboundDate = "old" + planColumnsName;
        const newOutboundDate = "new" + planColumnsName;
        const oldPlanCategories = (index: number) => [
            { key: 'old', value: intl.formatMessage({ id: oldOutboundDate }) },
            { key: `old${index}`, value: intl.formatDate(oldPlanDates[index], { dateStyle: 'medium' }) }
        ]
        const oldPlanColumns = oldPlanDates.map((plan, idx) => ({
            field: oldOutboundDate + idx,
            dataTypeName: 'number',
            title: 'Qty',
            categories: oldPlanCategories(idx),
            width: 190,
            getCellValue: getOldPlanQty(plan)
        }))
        const newPlanCategories = (index: number) => [
            { key: 'new', value: intl.formatMessage({ id: newOutboundDate }) },
            { key: `new${index}`, value: intl.formatDate(newPlanDates[index], { dateStyle: 'medium' }) }
        ]
        const newPlanColumns = newPlanDates.map((plan, idx) => ({
            field: newOutboundDate + idx,
            dataTypeName: 'newInboundPlan',
            title: 'Qty',
            categories: newPlanCategories(idx),
            width: 190,
            getCellValue: getNewPlanQty(plan)
        }))


        // outbound data column
        const oldSuppOutboundDate = proposeNew ? "oldOutboundDate" : "oldOutboundDateSupp";
        const newSuppOutboundDate = proposeNew ? "requestOutboundDate" : "newOutboundDateSupp";
        const oldOutboundPlanCategories = (index: number) => [
            { key: 'oldSuppOutbound', value: intl.formatMessage({ id: oldSuppOutboundDate }) },
            { key: `oldSuppOutbound${index}`, value: intl.formatDate(oldSuppOutboundPlanDates[index], { dateStyle: 'medium' }) }
        ]
        const oldSuppPlanColumns = oldSuppOutboundPlanDates.map((plan, idx) => ({
            field: oldSuppOutboundDate + idx,
            dataTypeName: 'number',
            title: 'Qty',
            categories: oldOutboundPlanCategories(idx),
            width: proposeNew ? 190 : 220,
            getCellValue: getOldOutboundPlanQty(plan)
        }))
        const newOutboundPlanCategories = (index: number) => [
            { key: 'plan', value: intl.formatMessage({ id: newSuppOutboundDate }) },
            { key: `plan${index}`, value: intl.formatDate(newSuppOutboundPlanDates[index], { dateStyle: 'medium' }) }
        ]
        const newSuppPlanColumns = newSuppOutboundPlanDates.map((plan, idx) => ({
            field: 'newPlanQty' + idx,
            dataTypeName: 'newSuppPlan',
            title: 'Qty',
            categories: newOutboundPlanCategories(idx),
            width: proposeNew ? 190 : 220,
            getCellValue: getNewOutboundPlanQty(plan),
            setCellValue: setNewOutboundPlanQty(plan)
        }))


        // all columns
        return showSuppOutboundFlag === 1 && proposeNew ?
            Arrays.concat(fixedColumns, firmColumns, oldSuppPlanColumns, oldPlanColumns, newSuppPlanColumns, newPlanColumns) :
            showSuppOutboundFlag === 1 && !proposeNew ?
                Arrays.concat(fixedColumns, firmColumns, oldPlanColumns, newPlanColumns, oldSuppPlanColumns, newSuppPlanColumns) : Arrays.concat(fixedColumns, firmColumns, oldPlanColumns, newPlanColumns)
    }, [cbdsType, getFlctuationFirm, getNewOutboundPlanQty, getNewPlanQty, getOldOutboundPlanQty, getOldPlanQty, headerInfo, intl, newPlanDates, newSuppOutboundPlanDates, oldPlanDates, oldSuppOutboundPlanDates, planColumnsName, proposeNew, requstType, setNewOutboundPlanQty, showSuppOutboundFlag])

    const [actions, setActions] = useState<RequestProps>({ open: false, editDate: null })

    const clickNewSuppPlan = useCallback((field: string) => {
        field.startsWith("newPlanQty") && setActions({ open: true, editDate: newSuppOutboundPlanDates[Number(field.substring(10))] })
    }, [newSuppOutboundPlanDates])
    const canNotClick = useCallback(() => {}, [])
    const newSuppPlan = useNewSuppPlan(proposeNew ? clickNewSuppPlan : canNotClick, proposeNew, proposeNew, intl.formatMessage({ id: 'requestOutboundDate' }))

    const newInboundPlan = useNewInboundPlan(headerInfo, partsDetailList, setPartsDetailList, proposeNew, proposeNew, intl.formatMessage({ id: "new" + planColumnsName }))

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])


    if (!partsDetailList) {
        return <></>
    }

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <FluctuationTypeProvider />
            <FcFluctuationTypeProvider />
            <NumberTypeProvider name="newSuppPlan" Category={newSuppPlan} />
            <NumberTypeProvider name="newInboundPlan" Category={newInboundPlan} />
            <Data rows={partsDetailList} columns={columns} />
            <ColumnFreeze />
            {proposeNew ? <></> : <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />}
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            {proposeNew ? <></> : <Searching ignoreCase Input={SearchInput} />}
            {proposeNew ? <></> : <Sorting />}
            {proposeNew ? <></> : <Filtering />}
        </DataGrid>
        <EditNewRequestDialog actions={actions} setActions={setActions} newSuppOutboundPlan={partsDetailList} setNewSuppOutboundPlan={setPartsDetailList} />
    </div>
})
interface RequestProps {
    open: boolean,
    editDate: Date | null
}

const EditNewRequestDialog = ({ actions, setActions, newSuppOutboundPlan, setNewSuppOutboundPlan }: {
    actions: RequestProps,
    setActions: React.Dispatch<React.SetStateAction<RequestProps>>,
    newSuppOutboundPlan: RequestPartsDetail[],
    setNewSuppOutboundPlan: React.Dispatch<React.SetStateAction<RequestPartsDetail[]>>,
}) => {
    const dispatch = useDispatch()
    const { open, editDate } = actions
    const [factor, setFactor] = useState<{ newSuppOutboundPlanDate: Date | null }>({ newSuppOutboundPlanDate: null })
    const handleClose = useCallback(() => setActions({ open: false, editDate: null }), [setActions])

    const intl = useIntl()

    useEffect(() => {
        setFactor({ newSuppOutboundPlanDate: editDate })
    }, [editDate])

    const aplyDate = useCallback(() => {
        if (!factor.newSuppOutboundPlanDate) {
            dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0001', args: ['newSuppOutboundPlan'] } }))
            return
        } else {
            const newSuppOutboundPlanDate = factor.newSuppOutboundPlanDate
            if (newSuppOutboundPlan.flatMap(f => f.newSuppOutboundDrQtys).some(s => moment(s!.drDate).format(moment.HTML5_FMT.DATE) === moment(newSuppOutboundPlanDate).format(moment.HTML5_FMT.DATE))
                && (!editDate || moment(editDate).format(moment.HTML5_FMT.DATE) !== moment(newSuppOutboundPlanDate).format(moment.HTML5_FMT.DATE))) {
                dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0381' } }))
                return
            } else {
                setNewSuppOutboundPlan(newSuppOutboundPlan => newSuppOutboundPlan.map(m => ({
                    ...m, newSuppOutboundDrQtys: !editDate ? [...m.newSuppOutboundDrQtys!, {
                        drDate: newSuppOutboundPlanDate,
                        drQty: 0
                    }] : m.newSuppOutboundDrQtys!.map(plan => (plan.drDate.getTime() === editDate.getTime()) ? { ...plan, drDate: newSuppOutboundPlanDate } : plan)
                })))
            }
        }
        // do reflect
        setFactor({ newSuppOutboundPlanDate: null })
        setActions({ open: false, editDate: null })
    }, [dispatch, editDate, factor.newSuppOutboundPlanDate, newSuppOutboundPlan, setActions, setNewSuppOutboundPlan])

    return <>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth keepMounted={false} fullScreen={false}>
            <DialogHeader onClose={handleClose}><FormattedMessage id="inputOutboundDate" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500}  >
                    <DateItem required field="newSuppOutboundPlanDate" labelWidth={120} label={intl.formatMessage({ id: 'requestOutboundDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={aplyDate} />
            </DialogActions>
        </DarkDialog>
    </>
}

const useNewSuppPlan = (click: (filed: string) => void, showButton: boolean, proposeNew: boolean, title?: string) => {
    const styles = useStyles()
    return (props: DataGridCategoryProps) => {
        const { category, tableColumns } = props
        return <Tooltip title={proposeNew ? "Edit" : ""} >
            <Typography variant="body2" className={styles.root} onClick={() => {
                if (title === undefined || title !== category) { click(tableColumns[0].field) }
            }}>
                {category} {showButton && (title === undefined || title !== category) && <IconButton style={{ zoom: 0.8 }}><Edit fontSize={"small"} /></IconButton>}
            </Typography >
        </Tooltip>
    }
}

const useNewInboundPlan = (
    headerInfo: RequestDetailHead,
    refreshPartsList: RequestPartsDetail[],
    setRefreshPartsList: React.Dispatch<React.SetStateAction<RequestPartsDetail[]>>,
    showButton: boolean,
    proposeNew: boolean,
    title?: string
) => {
    const styles = useStyles()
    const refreshInboundInfo = useRefreshInboundInfo()
    const refresh = useCallback(() => {
        if (proposeNew) {
            refreshInboundInfo({ header: headerInfo, detailList: [...refreshPartsList] }, { serialized: true }).then((result) => {
                setRefreshPartsList(result?.detailList ?? [])
            })
        } else {
        }
    }, [headerInfo, proposeNew, refreshInboundInfo, refreshPartsList, setRefreshPartsList])

    return (props: DataGridCategoryProps) => {
        const { category } = props
        return <Tooltip title={proposeNew ? "Refresh" : ""} >
            <Typography variant="body2" className={styles.root} onClick={refresh}>
                {category} {showButton && title !== undefined && title === category && <IconButton style={{ zoom: 0.8 }}><Refresh fontSize={"small"} /></IconButton>}
            </Typography >
        </Tooltip>
    }
}

const getDefaultColumns = (intl: IntlShape, cbdsType: string | number | null, headerInfo: RequestDetailHead, proposeNew: boolean) => {
    if (cbdsType === CbdsType.CUST) {
        if (proposeNew) {
            return [
                { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 220 },
                { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'poPartsNo' }), width: 220 },
                { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 140 },
                { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 140 },
            ]
        } else {
            return [
                { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
                { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 250 },
                { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 150 },
                { field: 'supplier', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
                { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150 },
                { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
                { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
                { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
                { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            ]
        }
    } else if (cbdsType === CbdsType.BU && (headerInfo.dataFlag === 0 || headerInfo.dataFlag === 1 || headerInfo.dataFlag === 3)) {
        if (proposeNew) {
            return [
                { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'poPartsNo' }), width: 250 },
                { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
                { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            ]
        } else {
            return [
                { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
                { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'poPartsNo' }), width: 250 },
                { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 150 },
                { field: 'supplier', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
                { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150 },
                { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
                { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
                { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
                { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            ]
        }
    } else if (cbdsType === CbdsType.BU && headerInfo.dataFlag === 2) {
        if (proposeNew) {
            return [
                { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'poPartsNo' }), width: 250 },
                { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
                { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            ]
        } else {
            return [
                { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
                { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'soPartsNo' }), width: 250 },
                { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 150 },
                { field: 'supplier', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
                { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150 },
                { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
                { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
                { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
                { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            ]
        }
    } else if (cbdsType === CbdsType.SUPP) {
        return [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'soPartsNo' }), width: 250 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 150 },
            { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150 },
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
            { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
        ]
    } else {
        return []
    }

}

const FluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRate = row.fluctuationRate
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRate ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRate === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FluctuationFormatter} />
}

const FcFluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRate = row.fcFluctuationRate
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRate ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRate === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FcFluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fcFluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FcFluctuationFormatter} />
}

const ApproveAction = ({ headerInfo, search }: { headerInfo: RequestDetailHead, search: () => void }) => {
    const approveMethod = useApproveRequest()
    const lineKey = headerInfo.roSoId + "#" + headerInfo.dataFlag
    const approve = useCallback(() => {
        approveMethod({ lineKeys: [lineKey] }, { serialized: true }).then(() => {
            search()
        })
    }, [approveMethod, lineKey, search])
    return <ApproveCallbackViewAction access="ORDER.ORS011.APPROVE" callback={approve} />
}

interface RejectRemark {
    remark?: string
}
const RejectAction = ({ headerInfo }: { headerInfo: RequestDetailHead }) => {
    const rejectRequest = useRejectRequest()
    const lineKey = headerInfo.roSoId + "#" + headerInfo.dataFlag
    const intl = useIntl()
    const navigate = useNavigate()
    const [open, setOpen] = useState<boolean>(false)
    const [remark, setRemark] = useState<RejectRemark>({})
    const dispatch = useDispatch()
    const title = useMemo(() => intl.formatMessage({ id: 'reject' }), [intl])
    const functionStore = useFunctionStore()
    const callReject = useCallback(() => {
        setOpen(true)
        setRemark({})
    }, [])
    const reject = useCallback(() => {
        const functionId = functionStore.register(() => {
            // do reject
            rejectRequest({ lineKeys: [lineKey], rejectRemark: remark.remark }, { serialized: true }).then(() => {
                navigate('/cc')
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
        // open pdialog
        setOpen(false)
    }, [dispatch, functionStore, navigate, rejectRequest, remark.remark, lineKey, title])
    return <Access access="ORDER.ORS010.REJECT">
        <RejectCallbackViewAction callback={callReject} />
        <CardDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="inputRejectReason" />
            </DialogHeader>
            <DialogContent>
                <Form data={remark} setData={setRemark} labelDisplay="block" helperDisplay="tooltip" minWidth={500} maxWidth={500} columnCount={1}>
                    <StringItem field="remark" labelDisplay='inline' labelWidth={120} label={intl.formatMessage({ id: 'field.rejectReason' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="reject" />} callback={reject} />
            </DialogActions>
        </CardDialog>
    </Access>
}

const ProposeNewAction = ({ headerInfo, partsList, proposeNew, search }: {
    headerInfo: RequestDetailHead,
    partsList: RequestPartsDetail[],
    proposeNew: true,
    search: () => void
}) => {
    const [refreshPartsList, setRefreshPartsList] = useState<RequestPartsDetail[]>([])
    const proposeNewConfirm = useProposeNewConfirm()
    const intl = useIntl()
    const [open, setOpen] = useState<boolean>(false)
    const [remark, setRemark] = useState<RejectRemark>({})
    const onChange = useCallback((event: any) => {
        setRemark({ remark: event.target.value })
    }, [])
    const dispatch = useDispatch()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const functionStore = useFunctionStore()
    const callProposeNewConfirm = useCallback(() => {
        setRefreshPartsList([...partsList])
        setOpen(true)
    }, [partsList])
    const confirm = useCallback(() => {
        const functionId = functionStore.register(() => {
            proposeNewConfirm({ header: { ...headerInfo, rejectRemark: remark.remark }, detailList: [...refreshPartsList] }, { serialized: true }).then(() => {
                setOpen(false)
                search()
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, headerInfo, proposeNewConfirm, refreshPartsList, remark.remark, search, title])

    const reset = useCallback(() => {
        // do reset
        setRefreshPartsList([...partsList])
        setRemark({ remark: '' })
    }, [partsList])

    const styles = useStyles()
    return <Access access="ORDER.ORS010.REJECT"/* 'STCK.ORS010.PROPOSENEW' */ >
        <ProposeNewCallbackViewAction callback={callProposeNewConfirm} />
        <CardDialog open={open} style={{ overflow: 'hidden' }} maxWidth="xl" fullWidth={true} fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="inputRejectReason" />
            </DialogHeader>
            <DialogContent>
                <Form data={remark} setData={setRemark} labelDisplay="block" helperDisplay="tooltip" columnCount={1}>
                    <SectionCard allowCollapse>
                        <SectionCardContent>
                            <DetailPartsInfo headerInfo={headerInfo} partsDetailList={refreshPartsList} proposeNew={proposeNew} setPartsDetailList={setRefreshPartsList} search={search} />
                        </SectionCardContent>
                    </SectionCard>
                    <textarea className={styles.search}
                        placeholder={intl.formatMessage({ id: 'reasonForRejection' })} onChange={onChange} value={remark.remark}>
                    </textarea>
                </Form>
            </DialogContent>
            <DialogActions style={{ position: "relative", right: "41.5%" }}>
                <DialogAction title={<FormattedMessage id="confirm" />} callback={confirm} />
                <DialogAction title={<FormattedMessage id="reset" />} callback={reset} />
            </DialogActions>
        </CardDialog>
    </Access>
}

const useStyles = makeStyles(theme => ({
    fulcuationCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
    search: {
        borderRadius: 8,
        minHeight: 40,
        height: 40,
        width: "100%",
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        outline: "none",
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: theme.palette.background.search.hover,
        },
        '&$focused': {
            backgroundColor: theme.palette.background.search.focused,
        },
        border: "1px solid rgb(208,208,208)",
        fontFamily: "inherit",
        color: "currentColor",
        fontSize: "1.1876em",
        resize: "none"
    },
    root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }

}))
