import { Tooltip } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import moment from "moment"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { ListTableRow } from "./ListTable"
import { OrderCalculationType } from "./OCCLS010Slice"

export interface CreateRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}

export function CreateRowAction(props: CreateRowActionProps) {
  const { tableRow, type } = props
  const row = tableRow.row as ListTableRow

  const display = row.status === OrderCalcStatus.PENDING_CALCULATION
  //&& row.inCurrentMonth=> remove first TODO
  const navigate = useNavigate()
  if (!display) {
    return null
  } else {
    return <Access access="STCK.OCCLS010.CREATE">
      <Tooltip title={<FormattedMessage id="create" />}>
        <IconButton onClick={() => {
          navigate({
            pathname: type === 'PNA'
              ? `/orderCalculationPNA/createCalculate-${row.orderGroupId}-${moment(row.orderMonth!).format('YYYYMM')}`
              : `/orderCalculation/createCalculate-${row.orderGroupId}-${moment(row.orderMonth!).format('YYYYMM')}`
          })
        }}>
          <Add />
        </IconButton>
      </Tooltip>
    </Access>
  }
}