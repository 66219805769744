import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AxiosError, AxiosResponse } from "axios"
import { useSelector } from "react-redux"
import appConfig from "../../../configs/appConfig"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { orderCalculationEhmGetBuyerOptions, orderCalculationEhmGetSellerOptions, orderCalculationGetBuyerOptions, orderCalculationGetCustomerOptions, orderCalculationGetSellerOptions, orderCalculationListView, orderCalculationListViewForEhm } from "../../../services/smt/api/OrderCalculationApi"
import { OrderCalcRunConditionVo, orderCalcRunConditionVoToJson } from "../../../services/smt/models/OrderCalcRunConditionVo"
import { OrderCalculationListViewCondition } from "../../../services/smt/models/OrderCalculationListViewCondition"
import { OrderCalculationListViewResult } from "../../../services/smt/models/OrderCalculationListViewResult"
import downloadResponse from "../../../utils/axios/downloadResponse"
import errorToNotification from "../../../utils/axios/errorToNotification"
import responseToNotification from "../../../utils/axios/responseToNotification"
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"
import { formify } from "../../../utils/formify"
import { FunctionStore } from "../../../utils/FunctionStore"
import { applicationActions } from "../../Application/applicationSlice"

export type OrderCalculationType = 'default' | 'PNA' | 'Ehm' | 'Cust'

export const occls010Slice = createSlice({
    name: 'occls010',
    initialState: {
        type: 'default' as OrderCalculationType,
        filters: {} as OrderCalculationListViewCondition,
        customerOptions: {} as Record<number, string>,
        buyerOptions: {} as Record<string, string>,
        sellerOptions: {} as Record<string, string>,
        results: [] as OrderCalculationListViewResult[],
        selections: [] as string[],
    },
    reducers: {
        setType: (state, action: PayloadAction<OrderCalculationType>) => {
            state.type = action.payload
        },
        setFilters: (state, action: PayloadAction<OrderCalculationListViewCondition>) => {
            state.filters = action.payload
        },
        setCustomerOptions: (state, action: PayloadAction<Record<number, string>>) => {
            state.customerOptions = action.payload
        },
        setBuyerOptions: (state, action: PayloadAction<Record<string, string>>) => {
            state.buyerOptions = action.payload
        },
        setSellerOptions: (state, action: PayloadAction<Record<string, string>>) => {
            state.sellerOptions = action.payload
        },
        setSearchResults: (state, action: PayloadAction<OrderCalculationListViewResult[]>) => {
            state.results = action.payload
        },
        setSelections: (state, action: PayloadAction<string[]>) => {
            state.selections = action.payload
        },
        unmount: (state) => {
            state.filters = {}
            state.customerOptions = {}
            state.buyerOptions = {}
            state.results = []
            state.selections = []
        },
    }
})

const getCustomerOptions = createAsyncThunk('occls010/getCustomerOptions', (_, thunk) => {
    return orderCalculationGetCustomerOptions(thunkAxiosInstance(thunk))
        .then(response => {
            thunk.dispatch(occls010Actions.setCustomerOptions(response.data))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const getBuyerOptions = createAsyncThunk('occls010/getBuyerOptions', (_, thunk) => {
    return orderCalculationGetBuyerOptions(thunkAxiosInstance(thunk))
        .then(response => {
            thunk.dispatch(occls010Actions.setBuyerOptions(response.data))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const getBuyerOptionsForEhm = createAsyncThunk('occls010/getBuyerOptionsForEhm', (_, thunk) => {
    return orderCalculationEhmGetBuyerOptions(thunkAxiosInstance(thunk))
        .then(response => {
            thunk.dispatch(occls010Actions.setBuyerOptions(response.data))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})



const getSellerOptions = createAsyncThunk('occls010/getSellerOptions', (_, thunk) => {
    return orderCalculationGetSellerOptions(thunkAxiosInstance(thunk))
        .then(response => {
            thunk.dispatch(occls010Actions.setSellerOptions(response.data))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const getSellerOptionsForEhm = createAsyncThunk('occls010/getSellerOptionsForEhm', (_, thunk) => {
    return orderCalculationEhmGetSellerOptions(thunkAxiosInstance(thunk))
        .then(response => {
            thunk.dispatch(occls010Actions.setSellerOptions(response.data))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})


const listView = createAsyncThunk('occls010/listView', (_, thunk) => {
    const filters = selectOCCLS010State(thunk.getState()).filters
    return orderCalculationListView(thunkAxiosInstance(thunk), filters)
        .then(response => {
            thunk.dispatch(occls010Actions.setSearchResults(response.data.data || []))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const listViewForEhm = createAsyncThunk('occls010/listViewForEhm', (_, thunk) => {
    const filters = selectOCCLS010State(thunk.getState()).filters
    return orderCalculationListViewForEhm(thunkAxiosInstance(thunk), filters)
        .then(response => {
            thunk.dispatch(occls010Actions.setSearchResults(response.data.data || []))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const upload = createAsyncThunk('occls010/upload', async (args: { file: File, functionStore: FunctionStore, type: OrderCalculationType }, thunk) => {
    const { file, functionStore, type } = args
    function success(response: AxiosResponse) {
        thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
        thunk.dispatch(type === 'Ehm' ? occls010Actions.listViewForEhm() : occls010Actions.listView())
    }
    function error(error: AxiosError) {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }

    const uploadFile = file
    function warn(warningMessages: any) {
        const functionId = functionStore.register(() => {
            thunk.dispatch(applicationActions.addMask())
            axiosInstance.post(url, formify({ file: uploadFile, isConfirm: 1 }))
                .then(success)
                .catch(error)
                .finally(() => {
                    thunk.dispatch(applicationActions.removeMask())
                })
        })
        thunk.dispatch(applicationActions.pushWarning({
            title: { code: 'confirm' },
            messages: warningMessages,
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }

    const axiosInstance = thunkAxiosInstance(thunk)
    const url = type === 'PNA'
        ? '/smt-api/ordermng/order-calculations/upload-pna'
        : type === 'Ehm' ?
            '/smt-api/ordermng/order-calculations/upload-ehm'
            : '/smt-api/ordermng/order-calculations/upload'
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(url, formify({ file }))
        .then(response => {
            const warningMessages = response.data
            if (warningMessages.length === 0) {
                success(response)
            } else {
                warn(warningMessages)
            }
        })
        .catch(error)
        .finally(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const download = createAsyncThunk('occls010/download', async (args: { type: OrderCalculationType }, thunk) => {
    const { type } = args
    const axiosInstance = thunkAxiosInstance(thunk)
    const url = type === 'PNA'
        ? '/smt-api/ordermng/order-calculations/download-pna'
        : type === 'Ehm' ?
            '/smt-api/ordermng/order-calculations/download-ehm'
            : '/smt-api/ordermng/order-calculations/download'

    const selections = selectOCCLS010State(thunk.getState()).selections
    const results = selectOCCLS010State(thunk.getState()).results
    const selectedGroups = results.filter(r => selections.includes(`${r.orderGroup.orderGroupId}-${r.orderTime.getTime()}-${r.orderCalcs?.[0]?.orderCalcId}`))
    const noSelections = selectedGroups.length === 0
    if (noSelections) {
        thunk.dispatch(applicationActions.pushError({
            title: { code: 'download' },
            messages: { code: 'w0002' }
        }))
        return
    }

    const hasPendingCalculation = selectedGroups.some(g => !g.orderCalcs || g.orderCalcs[0]?.status === OrderCalcStatus.PENDING_CALCULATION)
    const noOrderCalcs = selectedGroups.every(g => !g.orderCalcs || g.orderCalcs.length === 0)
    if (hasPendingCalculation || noOrderCalcs) {
        thunk.dispatch(applicationActions.pushError({
            title: { code: 'download' },
            messages: { code: 'w0606' }
        }))
        return
    }

    const orderCalcNos = selectedGroups.map(g => g.orderCalcs?.[0]?.orderCalcNo).filter(Boolean)
    return await axiosInstance.post(url, { orderCalcNos }, { responseType: 'blob' }).then(response => {
        downloadResponse(response)
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
})

const downloadWestOrderRPAJob = createAsyncThunk('occls010/download', async (args: { type: OrderCalculationType }, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const url = '/smt-api/ordermng/order-calculations/download-west'

    const selections = selectOCCLS010State(thunk.getState()).selections
    const results = selectOCCLS010State(thunk.getState()).results
    const selectedGroups = results.filter(r => selections.includes(`${r.orderGroup.orderGroupId}-${r.orderTime.getTime()}-${r.orderCalcs?.[0]?.orderCalcId}`))
    const noSelections = selectedGroups.length === 0
    if (noSelections) {
        thunk.dispatch(applicationActions.pushError({
            title: { code: 'download' },
            messages: { code: 'w0002' }
        }))
        return
    }

    const hasPendingCalculation = selectedGroups.some(g => !g.orderCalcs || g.orderCalcs[0]?.status === OrderCalcStatus.PENDING_CALCULATION)
    const noOrderCalcs = selectedGroups.every(g => !g.orderCalcs || g.orderCalcs.length === 0)
    if (hasPendingCalculation || noOrderCalcs) {
        thunk.dispatch(applicationActions.pushError({
            title: { code: 'download' },
            messages: { code: 'w0606' }
        }))
        return
    }

    const orderCalcNos = selectedGroups.map(g => g.orderCalcs?.[0]?.orderCalcNo).filter(Boolean)
    return await axiosInstance.post(url, { orderCalcNos }, { responseType: 'blob' }).then(response => {
        downloadResponse(response)
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
})

const review = createAsyncThunk('occls010/review', async (args: { data: OrderCalcRunConditionVo, callback: (orderCalcNo: string) => void }, thunk) => {
    const { data, callback } = args
    const axiosInstance = thunkAxiosInstance(thunk)
    const url = '/smt-api/ordermng/order-calculations/review'
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(url, orderCalcRunConditionVoToJson(data))
        .then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            callback(response.data.orderCalcNo)
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
        .finally(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const reviewPNA = createAsyncThunk('occls010/review', async (args: { data: OrderCalcRunConditionVo, callback: (orderCalcNo: string) => void }, thunk) => {
    const { data, callback } = args
    const axiosInstance = thunkAxiosInstance(thunk)
    const url = '/smt-api/ordermng/order-calculations/review-pna'
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(url, orderCalcRunConditionVoToJson(data))
        .then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            callback(response.data.orderCalcNo)
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
        .finally(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const reviewSMT = createAsyncThunk('occls010/review', async (args: { data: OrderCalcRunConditionVo, callback: (orderCalcNo: string) => void }, thunk) => {
    const { data, callback } = args
    const axiosInstance = thunkAxiosInstance(thunk)
    const url = '/smt-api/ordermng/order-calculations/review-ehm'
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(url, orderCalcRunConditionVoToJson(data))
        .then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            callback(response.data.orderCalcNo)
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
        .finally(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const placeOrder = createAsyncThunk('occls010/placeOrder', async (args: { data: { orderCalcNo: string }, callback: (poGroupId: string) => void }, thunk) => {
    const { data, callback } = args
    const axiosInstance = thunkAxiosInstance(thunk)
    const url = '/smt-api/ordermng/createRegularOrder'
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(url, data)
        .then((response: AxiosResponse<{ poGroupId: string }>) => { callback(response.data.poGroupId) })
        .catch(error => thunk.dispatch(applicationActions.pushNotification(errorToNotification(error))))
        .finally(() => thunk.dispatch(applicationActions.removeMask()))
})

const placeOrderPNA = createAsyncThunk('occls010/placeOrderPNA', async (args: { data: { orderCalcNo: string }, callback: (poGroupId: string) => void }, thunk) => {
    const { data, callback } = args
    const axiosInstance = thunkAxiosInstance(thunk)
    const url = '/smt-api/ordermng/createRegularOrderPNA'
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(url, data)
        .then((response: AxiosResponse<{ poGroupId: string }>) => { callback(response.data.poGroupId) })
        .catch(error => thunk.dispatch(applicationActions.pushNotification(errorToNotification(error))))
        .finally(() => thunk.dispatch(applicationActions.removeMask()))
})

const remove = createAsyncThunk('occls010/delete', async (args: { orderCalcNo: string, type: OrderCalculationType }, thunk) => {
    const { orderCalcNo, type } = args
    const axiosInstance = thunkAxiosInstance(thunk)
    const url = '/smt-api/ordermng/order-calculations/delete'
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(url, { orderCalcNo })
        .then((response) => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            thunk.dispatch(type === 'Ehm' ? occls010Actions.listViewForEhm() : occls010Actions.listView())
        })
        .catch(error => thunk.dispatch(applicationActions.pushNotification(errorToNotification(error))))
        .finally(() => thunk.dispatch(applicationActions.removeMask()))
})

const withdraw = createAsyncThunk('occls010/withdraw', async (args: { poGroupId: string, type: OrderCalculationType }, thunk) => {
    const { poGroupId, type } = args
    const axiosInstance = thunkAxiosInstance(thunk)
    const url = '/smt-api/ordermng/order-calculations/withdraw'
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(url, { poGroupId })
        .then((response) => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            thunk.dispatch(type === 'Ehm' ? occls010Actions.listViewForEhm() : occls010Actions.listView())
        }).catch(error => thunk.dispatch(applicationActions.pushNotification(errorToNotification(error))))
        .finally(() => thunk.dispatch(applicationActions.removeMask()))
})

export const occls010Actions = {
    ...occls010Slice.actions,
    getCustomerOptions,
    getBuyerOptions,
    getBuyerOptionsForEhm,
    getSellerOptions,
    getSellerOptionsForEhm,
    listView,
    listViewForEhm,
    upload,
    download,
    downloadWestOrderRPAJob,
    review,
    reviewPNA,
    reviewSMT,
    placeOrder,
    placeOrderPNA,
    remove,
    withdraw,
}

const occls010Reducer = occls010Slice.reducer
type State = ReturnType<typeof occls010Reducer>
function selectOCCLS010State(state: any): State {
    return state[appConfig.appFullName][occls010Slice.name]
}

export function useOCCLS010Selector<R>(selector: (state: State) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectOCCLS010State(state)), equalityFn)
}