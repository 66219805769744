import { Tooltip } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import moment from "moment"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { ListTableEhmRow } from "./ListTableEhm"
import { OrderCalculationType } from "./OCCLS010Slice"

export interface CreateRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}

export function CreateRowActionForEhm(props: CreateRowActionProps) {
  const { tableRow } = props
  const row = tableRow.row as ListTableEhmRow
  const bySeller = row.sellerCode !== null && row.sellerCode !== undefined
  const display = row.status === OrderCalcStatus.PENDING_CALCULATION
  const navigate = useNavigate()
  if (!display) {
    return null
  } else {
    return <Access access="STCK.OCCLS010.CREATE">
      <Tooltip title={<FormattedMessage id="create" />}>
        <IconButton onClick={() => {
          navigate({
            pathname: bySeller ? `/orderCalculationEhm/createCalculateBySeller-${row.orderGroupId}-${moment(row.orderMonth!).format('YYYYMM')}`
              : `/orderCalculationEhm/createCalculate-${row.orderGroupId}-${moment(row.orderMonth!).format('YYYYMM')}`
          })
        }}>
          <Add />
        </IconButton>
      </Tooltip>
    </Access>
  }
}