import { Dialog, DialogActions, DialogContent, FormControl, Typography } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Selection, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useReviewOrderCalculationAfterUploadNewUsage } from "../../../services/smt/api/OrderCalculationApi"
import { OrderCalculationReviewResult } from "../../../services/smt/models/OrderCalculationReviewResult"



export interface OCCLS016EhmForSellerPcUiProps {
    mode: ScreenMode,
    orderCalcNo?: string,
    data: OrderCalculationReviewResult[],
    latestUsageVersion?: string,
    search: (orderCalcNo?: string) => void,
    isSame: boolean
}

export const OCCLS016EhmForSellerPcUi = (props: OCCLS016EhmForSellerPcUiProps) => {
    const { orderCalcNo, latestUsageVersion, search } = props
    const [selections, setSelections] = useState<number[]>([])
    const [displayRecalc, setDisplayRecalc] = useState<boolean>(false)

    // useEffect(() => {
    //     if (mode === ScreenMode.EDIT && !isSame) {
    //         setDisplayRecalc(false)
    //     }
    // }, [isSame, mode])

    const reviewOrderCalculationAfterUploadNewUsage = useReviewOrderCalculationAfterUploadNewUsage()
    const refresh = useCallback(() => {
        if (orderCalcNo) {
            reviewOrderCalculationAfterUploadNewUsage({ orderCalcNo: orderCalcNo }, { serialized: true }).then(result => {
                setDisplayRecalc(false)
                search(orderCalcNo)
            })
        }
    }, [orderCalcNo, reviewOrderCalculationAfterUploadNewUsage, search])
    // const actions = mode === ScreenMode.EDIT ? [<ReviewButtion orderCalcNo={orderCalcNo} data={data} selections={selections} />] : [];
    return <View flex actions={[]}>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} selections={selections} setSelections={setSelections} />
            </SectionCardContent>
        </SectionCard>
        <Dialog open={displayRecalc} style={{ overflow: 'hidden' }} fullScreen={false}>
            <DialogHeader onClose={() => setDisplayRecalc(false)}>
                <FormattedMessage id="Re-execute order calculation" />
            </DialogHeader>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormattedMessage id={"A new customer usage [" + latestUsageVersion + "] has been uploaded, would you like to re-execute order calculation based on the new customer usage?"} />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="no" />} callback={() => setDisplayRecalc(false)} />
                <DialogAction title={<FormattedMessage id="yes" />} callback={refresh} />
            </DialogActions>
        </Dialog>
    </View >
}

const DataTable = ({ data, selections, setSelections }: {
    data: OrderCalculationReviewResult[],
    selections: number[],
    setSelections: React.Dispatch<React.SetStateAction<number[]>>
}) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
        { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 250 },
        { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerPartsNo' }), width: 200 },
        { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerPartsNo' }), width: 200 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalRefNo' }), width: 250 },
        { field: 'expCountryForSeller', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.expCountry' }), width: 200 },
        { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 180 },
        { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 200 },
        { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'orderLot' }), width: 200 },
        { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'spq' }), width: 200 },
        { field: 'previoudSuggestQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'previouslySuggestedOrderQty' }), width: 200 },
        { field: 'currentFirmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentFirmQty' }), width: 200 },
        { field: 'currentUsageQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentCustomerUsage' }), width: 300 },
        { field: 'currentSuggestQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentSuggestedOrderQty' }), width: 200 },
        { field: 'suggestAddtionalQty', dataTypeName: 'suggestedAdditionalQty', title: intl.formatMessage({ id: 'suggestedAdditionalQty' }), width: 200 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.orderCalcPartsId, [])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <PercentTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <SuggestedAdditionalQtyProvider />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Sorting />
        <Filtering />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={250} />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    </DataGrid>
}

const SuggestedAdditionalQtyFormatter = ({ value, row }: StringFormatterProps) => {
    const suggestedAdditionalQty = row.suggestAddtionalQty
    const color = suggestedAdditionalQty <= 0 ? '' : '#00B050'
    const displayVal = (suggestedAdditionalQty != null && suggestedAdditionalQty !== undefined && suggestedAdditionalQty > 0) ? suggestedAdditionalQty : '-'
    return <div style={{ color: color, width: '100%', textAlign: 'right' }}>
        <Typography variant="body2" >{displayVal}</Typography>
    </div>
}

const SuggestedAdditionalQtyProvider = () => {
    return <StringTypeProvider name="suggestedAdditionalQty" align="center" Formatter={SuggestedAdditionalQtyFormatter} />
}

