import { useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetOrderCalculationDetail, useGetOrderCalculationEhmForBuyerDetail, useGetOrderCalculationEhmForSellerDetail } from "../../../services/smt/api/OrderCalculationApi"
import { SafetyStockUom } from "../../../services/smt/enums/SafetyStockUom"
import { OrderCalculationDetailResult, OrderCalculationPartsDetailEntity, OrderCalculationPeriodDetailEntity } from "../../../services/smt/models/OrderCalculationDetailResult"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { OCCLS014EhmForBuyerPcUi } from "./OCCLS014EhmForBuyerPcUi"
import { OCCLS014EhmForSellerPcUi } from "./OCCLS014EhmForSellerPcUi"
import { OCCLS014PNAPcUi } from "./OCCLS014PNAPcUi"
import { OCCLS014PcUi } from "./OCCLS014PcUi"

export interface OrderCalculationPartsDetailResult extends Omit<OrderCalculationPartsDetailEntity, 'periodInfoList'> {
    periodInfoList: OrderCalculationPeriodDetailResult[],
}

export interface OrderCalculationPeriodDetailResult extends OrderCalculationPeriodDetailEntity {
    usageFluctuation: number | null,
    ocFluctuation: number | null,
    stockAtEndQty: number,
    stockAtEnd: string,
    riskyInventoryFlag: string,
}

export const OCCLS014 = (_props: any) => {
    const { path } = useMatch()
    const { orderCalcId } = useParams()
    // const mode = path === '/orderCalculation/editRegular-:orderCalcId' ? ScreenMode.EDIT : ScreenMode.VIEW
    const mode = ScreenMode.VIEW
    const [headerInfo, setHeaderInfo] = useState<OrderCalculationDetailResult>({} as any)
    const [partsDetailList, setPartsDetailList] = useState<OrderCalculationPartsDetailResult[]>([])

    const getOrderCalculationDetail = useGetOrderCalculationDetail()
    const getOrderCalculationEhmForBuyerDetail = useGetOrderCalculationEhmForBuyerDetail()
    const getOrderCalculationEhmForSellerDetail = useGetOrderCalculationEhmForSellerDetail()
    const getOrderCalcDetail = path.includes("ForBuyer") ? getOrderCalculationEhmForBuyerDetail
        : path.includes("ForSeller") ? getOrderCalculationEhmForSellerDetail
            : getOrderCalculationDetail
    const search = useCallback((orderCalcId?: string) => {
        if (orderCalcId === undefined) return;
        getOrderCalcDetail({ orderCalcId: orderCalcId }, { silent: true, serialized: true }).then(result => {
            if (result) {
                setHeaderInfo({ ...result, partsDetailList: [] })
                setPartsDetailList(recalculation(result.partsDetailList ?? []))
            }
        })
    }, [getOrderCalcDetail])

    useSearchOnLoad(search, orderCalcId)

    if (path === "/orderCalculationPNA/editRegular-:orderCalcId"
        || path === "/orderCalculationPNA/viewRegular-:orderCalcId") {

        return <OCCLS014PNAPcUi
            orderCalcId={orderCalcId}
            search={search}
            mode={mode}
            headerInfo={headerInfo}
            partsDetailList={partsDetailList}
        />
    } else if (path === "/orderCalculationEhm/editRegularForBuyer-:orderCalcId"
        || path === "/orderCalculationEhm/viewRegularForBuyer-:orderCalcId") {

        return <OCCLS014EhmForBuyerPcUi
            orderCalcId={orderCalcId}
            search={search}
            mode={mode}
            headerInfo={headerInfo}
            partsDetailList={partsDetailList}
        />
    } else if (path === "/orderCalculationEhm/editRegularForSeller-:orderCalcId"
        || path === "/orderCalculationEhm/viewRegularForSeller-:orderCalcId") {

        return <OCCLS014EhmForSellerPcUi
            orderCalcId={orderCalcId}
            search={search}
            mode={mode}
            headerInfo={headerInfo}
            partsDetailList={partsDetailList}
        />
    } else {

        return <OCCLS014PcUi
            orderCalcId={orderCalcId}
            search={search}
            mode={mode}
            headerInfo={headerInfo}
            partsDetailList={partsDetailList}
        />
    }
}

export const recalculation = (partsDetails: OrderCalculationPartsDetailEntity[]) => {
    return partsDetails.map(part => {
        const totalStockQty = (part.warehouseStock ?? 0) + (part.customerStock ?? 0) + (part.wipQty ?? 0)
        const periodInfoList: OrderCalculationPeriodDetailResult[] = []
        const last = part.periodInfoList.length - 1

        part.periodInfoList.forEach((period, index, arr) => {

            // fluctuation
            const usageFluctuation = period.previousUsageQty && period.previousUsageQty !== 0 ? Number((((period.currentUsageQty ?? 0) / period.previousUsageQty) - 1).toFixed(2)) : null
            const ocFluctuation = period.lastFcQty && period.lastFcQty !== 0 ? Number((((period.finalQty ?? 0) / period.lastFcQty) - 1).toFixed(2)) : null
          
            // stockAtEndQty
            const stockAtEndQty = periodInfoList.length === 0
                ? totalStockQty + (period.previousOrderBalance ?? 0) + (period.currentOrderBalance ?? 0) - (period.currentUsageBalance ?? 0)
                : periodInfoList[periodInfoList.length - 1].stockAtEndQty + (period.periodIndex < 0 ? (period.currentOrderBalance ?? 0) : (period.finalQty ?? 0)) - (period.currentUsageQty ?? 0)
            // stockAtEnd
            const stockAtEnd = last === index ? '-' : prepareStockAtEnd(part.safetyStockUom, stockAtEndQty, period.nextWorkingDay, arr[index + 1].currentUsageQty, part.spq)
            const stockAtEndValue = last === index ? 0 : prepareStockAtEndValue(part.safetyStockUom, stockAtEndQty, period.nextWorkingDay, arr[index + 1].currentUsageQty, part.spq)
            // riskyInventoryFlag
            const riskyInventoryFlag = stockAtEnd === '-' ? '' : prepareRiskyInventoryFlag(stockAtEndValue, part.safetyStock, part.maxStock, part.safetyStockUom)
            // push
            periodInfoList.push({ ...period, usageFluctuation, ocFluctuation, stockAtEndQty, stockAtEnd, riskyInventoryFlag })
        }, [])
        return { ...part, periodInfoList: periodInfoList } as OrderCalculationPartsDetailResult
    })
}

const prepareStockAtEnd = (safetyStockUom?: number, stockAtEndQty?: number, workingDays?: number, nextCustomerUsage?: number, spq?: number) => {
    if (SafetyStockUom.BY_STOCK === safetyStockUom) {
        return nextCustomerUsage && workingDays ? ((stockAtEndQty ?? 0) / (nextCustomerUsage / workingDays)).toFixed(0) : '-'
    } else if (SafetyStockUom.BY_SIGN === safetyStockUom) {
        return nextCustomerUsage ? ((stockAtEndQty ?? 0) * 100 / nextCustomerUsage).toFixed(0) + '%' : '-'
    } else {
        return ((stockAtEndQty ?? 0) / (spq ? spq : 1)).toFixed(0)
    }
}

const prepareStockAtEndValue = (safetyStockUom?: number, stockAtEndQty?: number, workingDays?: number, nextCustomerUsage?: number, spq?: number) => {
    if (SafetyStockUom.BY_STOCK === safetyStockUom) {
        return nextCustomerUsage && workingDays ? ((stockAtEndQty ?? 0) / (nextCustomerUsage / workingDays)) : 0
    } else if (SafetyStockUom.BY_SIGN === safetyStockUom) {
        return nextCustomerUsage ? ((stockAtEndQty ?? 0) * 100 / nextCustomerUsage) : 0
    } else {
        return ((stockAtEndQty ?? 0) / (spq ?? 1))
    }
}

const prepareRiskyInventoryFlag = (stockAdEnd: number, safetyStock?: number, maxStock?: number, safetyStockUom?: number) => {
    if (safetyStock === undefined) return ''
    if (SafetyStockUom.BY_SIGN === safetyStockUom) {
        const acutalStockAdEndNum = stockAdEnd / 100
        return acutalStockAdEndNum < safetyStock ? 'X' : (maxStock === undefined ? '' : (maxStock < acutalStockAdEndNum ? '+' : ''))
    } else {
        return stockAdEnd < safetyStock ? 'X' : (maxStock === undefined ? '' : (maxStock < stockAdEnd ? '+' : ''))
    }
}