import { DialogActions, DialogContent } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, Form, NumberItem, StringItem } from "@rithe/form"
import { Records, arrx } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DetailRedirectRowAction } from "../../../components/DataGrid/rowActions/DetailRedirectRowAction"
import { ApproveCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/ApproveCallbackToolbarAction"
import { RejectCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/RejectCallbackToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useApproveRequest, useRejectRequest } from "../../../services/order/apis/CCRequestApi"
import { CCRequestListFactor } from "../../../services/order/models/CCRequestListFactor"
import { CCRequestListResult } from "../../../services/order/models/CCRequestListResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface ORS010PcUiProps {
    filters: CCRequestListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CCRequestListFactor>>,
    search: (filters: CCRequestListFactor) => void,
    data: CCRequestListResult[],
    totalCount: number,
    customerList: { customerId: number, customerCode: string }[],
    supplierList: { supplierId: number, supplierCode: string }[],
}

export const ORS010PcUi = (props: ORS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}


const DataTable = ({ filters, setFilters, search, customerList, supplierList, data }: ORS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])

    const cbdsType = useGetCompanyType()
    const columns = useMemo(() => arrx(
        { field: 'requestNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestNo' }), width: 300 },
        { field: 'requestFrom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestFrom' }), width: 180 },
        { field: 'requestType', dataTypeName: CodeCategory.RequestType, title: intl.formatMessage({ id: 'field.requestType' }), width: 180 },
        { field: 'requestStatus', dataTypeName: CodeCategory.RequestStatus, title: intl.formatMessage({ id: 'requestStatus' }), width: 200 },
        { field: 'customerOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerOrderNo' }), width: 300 },
        { field: 'salesOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 300 },
        { field: 'orderVersion', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderVersion' }), width: 180 },
        { field: 'customerRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerRefNo' }), width: 300 },
        cbdsType !== CbdsType.CUST && { field: 'customer', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 180 },
        { field: 'supplier', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 150 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'contractNo' }), width: 300 },
        { field: 'contractRouteNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 300 },
        { field: 'handledBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'handledBy' }), width: 180 },
        { field: 'handledDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'handledDate' }), width: 180 },
    ), [cbdsType, intl])

    const getRowId = useCallback((row: any) => row.roSoId + "#" + row.dataFlag, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, supplierList, customerList }), [filters, setFilters, search, supplierList, customerList])
    const actionProps1 = useMemo(() => ({ selections, search, filters }), [filters, search, selections])
    const display1 = useCallback(() => (cbdsType === CbdsType.BU && (data.map((data) => data.dataFlag)).includes(3) ? true : false) || cbdsType === CbdsType.SUPP || cbdsType === CbdsType.CUST, [cbdsType, data])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RequestType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RequestStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={ApproveAction} actionProps={actionProps1} display={display1} />
        <ToolbarActionProvider Action={RejectAction} actionProps={actionProps1} display={display1} />
        <RowActionProvider name="detail" Action={DetailRowAction} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['customerOrderNo', 'salesOrderNo', 'orderVersion', 'customerRefNo', 'contractNo', 'handledBy', 'handledDate']}
            columnSettings={{
                orderNo: { disableUserControl: true },
                customerCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={54} />
    </DataGrid>
}

const FilterItem = (props: {
    supplierList: { supplierId: number, supplierCode: string }[],
    customerList: { customerId: number, customerCode: string }[],
    filters: CCRequestListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CCRequestListFactor>>,
    search: (filters: CCRequestListFactor) => void
}) => {
    const { filters, setFilters, customerList, supplierList, search } = props
    const customerEntries: [number, string][] = useMemo(() => customerList.map(m => [m.customerId, m.customerCode]), [customerList])
    const supplierEntries: [number, string][] = useMemo(() => supplierList.map(m => [m.supplierId, m.supplierCode]), [supplierList])

    const handledDateGetValue = useCallback((filters: CCRequestListFactor) => {
        return [filters.handledDateStart ?? null, filters.handledDateEnd ?? null]
    }, [])

    const handledDateMapValue = useCallback((filters: CCRequestListFactor, value: any) => {
        return { ...filters ?? {}, handledDateStart: value[0], handledDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: CCRequestListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: CCRequestListFactor) => {
        return [
            filters.requestNo,
            filters.requestTypeList,
            filters.requestStatusList,
            filters.customerOrderNo,
            filters.salesOrderNo,
            filters.orderVersion,
            filters.customerRefNo,
            filters.customerIdList,
            filters.supplierIdList,
            filters.orderTypeList,
            filters.orderFrequencyList,
            filters.contractNo,
            filters.contractRouteNo,
            filters.handledBy,
            filters.handledDateStart || filters.handledDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="requestNo" label={intl.formatMessage({ id: 'field.requestNo' })} />
        <CodesItem field="requestTypeList" label={intl.formatMessage({ id: 'field.requestType' })} code={CodeCategory.RequestType} />
        <CodesItem field="requestStatusList" label={intl.formatMessage({ id: 'requestStatus' })} code={CodeCategory.RequestStatus} />
        <StringItem field="customerOrderNo" label={intl.formatMessage({ id: 'field.customerOrderNo' })} />
        <StringItem field="salesOrderNo" label={intl.formatMessage({ id: 'salesOrderNo' })} />

        <NumberItem field="orderVersion" label={intl.formatMessage({ id: 'field.orderVersion' })} />
        <StringItem field="customerRefNo" label={intl.formatMessage({ id: 'field.customerRefNo' })} />
        <EntriesItem field="customerIdList" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customerEntries} />
        <EntriesItem field="supplierIdList" label={intl.formatMessage({ id: 'field.supplierCode' })} entries={supplierEntries} />
        <CodesItem field="orderTypeList" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />

        <CodesItem field="orderFrequencyList" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'contractNo' })} />
        <StringItem field="contractRouteNo" label={intl.formatMessage({ id: 'field.contrRouteCode' })} />
        <StringItem field="handledBy" label={intl.formatMessage({ id: 'handledBy' })} />
        <DateRangeItem field="handleDate" label={intl.formatMessage({ id: 'handleDate' })} getValue={handledDateGetValue} mapValue={handledDateMapValue} />
    </FilterToolbarItem>
}

interface RejectRemark {
    remark?: string
}

const ApproveAction = ({ selections, search, filters }: { selections: string[], search: (filter: CCRequestListFactor) => void, filters: CCRequestListFactor }) => {
    const dispatch = useDispatch()
    const approveApi = useApproveRequest()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'approve' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'approve' }, messages: { code: 'w0002' } }))
        } else {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                approveApi({ lineKeys: selections }, { serialized: true }).then(() => search(filters)).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }, [approveApi, dispatch, filters, functionStore, search, selections, title])
    return <ApproveCallbackToolbarAction access="ORDER.ORS010.APPROVE" callback={callback} disabled={disabled} />
}

const RejectAction = ({ selections, search, filters }: { selections: string[], search: (filters: CCRequestListFactor) => void, filters: CCRequestListFactor }) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const [remark, setRemark] = useState<RejectRemark>({})
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'reject' }), [intl])
    const callback = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'reject' }, messages: { code: 'w0002' } }))
        } else {
            setOpen(true)
            setRemark({})
        }
    }, [dispatch, selections.length])

    const rejectApi = useRejectRequest()
    const [disabled, setDisabled] = useState<boolean>(false)
    const reject = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            rejectApi({ lineKeys: selections, rejectRemark: remark.remark }, { serialized: true }).then(() => {
                search(filters)
                setOpen(false)
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
        // open pdialog
        setOpen(false)
    }, [dispatch, filters, functionStore, rejectApi, remark.remark, search, selections, title])

    return <>
        <RejectCallbackToolbarAction access="ORDER.ORS010.REJECT" callback={callback} />
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="inputRejectReason" />
            </DialogHeader>
            <DialogContent>
                <Form data={remark} setData={setRemark} labelDisplay="block" helperDisplay="tooltip" minWidth={500} maxWidth={500} columnCount={1}>
                    <StringItem field="remark" labelDisplay='inline' label={intl.formatMessage({ id: 'remark' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="reject" />} callback={reject} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </>
}

const DetailRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const dataFlag = Number(tableRow?.rowId?.toString().split("#")[1])
    const roSoId = Number(tableRow?.rowId?.toString().split("#")[0])
    const soId = tableRow?.row?.soId;
    const requestType = tableRow?.row?.requestType;
    const path = useCallback((tableRow: TableRow) => `/cc/-${roSoId}-${dataFlag}-${soId}-${requestType}`, [dataFlag, roSoId, soId, requestType])
    return <DetailRedirectRowAction tableRow={tableRow} access="ORDER.ORS010.VIEWDETAIL" path={path} />
}