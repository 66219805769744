import { Dialog, DialogActions, DialogContent, FormControl, RadioGroup, Typography } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Selection, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Form, Message } from '@rithe/form'
import { StringItem } from "@rithe/form/dist/components/StringItem"
import { Records } from "@rithe/utils"
import moment from "moment"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { CallbackViewAction } from "../../../components/Action/CallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useReviewOrderCalculationAfterUploadNewUsage, useReviewOrderCalculationByCondition } from "../../../services/smt/api/OrderCalculationApi"
import { OrderCalculationReviewResult } from "../../../services/smt/models/OrderCalculationReviewResult"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"



export interface OCCLS016PcUiProps {
    mode: ScreenMode,
    orderCalcNo?: string,
    data: OrderCalculationReviewResult[],
    latestUsageVersion?: string,
    search: (orderCalcNo?: string) => void
}

export const OCCLS016PcUi = (props: OCCLS016PcUiProps) => {
    const { mode, orderCalcNo, data, latestUsageVersion, search } = props
    const [selections, setSelections] = useState<number[]>([])
    const [displayRecalc, setDisplayRecalc] = useState<boolean>(false)

    // useEffect(() => {
    //     setDisplayRecalc(latestUsageVersion && data && data.length > 0 && mode === ScreenMode.EDIT ? latestUsageVersion !== data[0].usageVersion : false)
    // }, [data, latestUsageVersion, mode])

    const reviewOrderCalculationAfterUploadNewUsage = useReviewOrderCalculationAfterUploadNewUsage()
    const refresh = useCallback(() => {
        if (orderCalcNo) {
            reviewOrderCalculationAfterUploadNewUsage({ orderCalcNo: orderCalcNo }, { serialized: true }).then(result => {
                setDisplayRecalc(false)
                search(orderCalcNo)
            })
        }
    }, [orderCalcNo, reviewOrderCalculationAfterUploadNewUsage, search])

    return <View flex actions={mode === ScreenMode.EDIT ? <ReviewButtion orderCalcNo={orderCalcNo} data={data} selections={selections} /> : []}>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} selections={selections} setSelections={setSelections} />
            </SectionCardContent>
        </SectionCard>
        <Dialog open={displayRecalc} style={{ overflow: 'hidden' }} fullScreen={false}>
            <DialogHeader onClose={() => setDisplayRecalc(false)}>
                <FormattedMessage id="Re-execute order calculation" />
            </DialogHeader>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormattedMessage id={"A new customer usage [" + latestUsageVersion + "] has been uploaded, would you like to re-execute order calculation based on the new customer usage?"} />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="no" />} callback={() => setDisplayRecalc(false)} />
                <DialogAction title={<FormattedMessage id="yes" />} callback={refresh} />
            </DialogActions>
        </Dialog>
    </View >
}

const DataTable = ({ data, selections, setSelections }: {
    data: OrderCalculationReviewResult[],
    selections: number[],
    setSelections: React.Dispatch<React.SetStateAction<number[]>>
}) => {
    const intl = useIntl()
    const monthStr = useMemo(() => data && data.length > 0 ? "[" + moment(data[0].usageDate).format('YYYYMMDD') + "]" : '', [data])
    const columns = useMemo(() => [
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 250 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
        { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 200 },
        { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerPartsNo' }), width: 200 },
        { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 200 },
        { field: 'expCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.expCountry' }), width: 200 },
        { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
        { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 200 },
        { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 200 },
        { field: 'previoudSuggestQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'previouslySuggestedOrderQty' }), width: 200 },
        { field: 'currentFirmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentFirmQty' }), width: 200 },
        { field: 'currentUsageQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentCustomerUsage' }) + monthStr, width: 300 },
        { field: 'currentSuggestQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentSuggestedOrderQty' }), width: 200 },
        { field: 'suggestAddtionalQty', dataTypeName: 'suggestedAdditionalQty', title: intl.formatMessage({ id: 'suggestedAdditionalQty' }), width: 200 },
    ], [intl, monthStr])

    const getRowId = useCallback((row: any) => row.orderCalcPartsId, [])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <PercentTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <SuggestedAdditionalQtyProvider />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Sorting />
        <Filtering />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={250} />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    </DataGrid>
}

const SuggestedAdditionalQtyFormatter = ({ value, row }: StringFormatterProps) => {
    const suggestedAdditionalQty = row.suggestAddtionalQty
    const color = suggestedAdditionalQty <= 0 ? '' : '#00B050'
    const displayVal = (suggestedAdditionalQty != null && suggestedAdditionalQty !== undefined && suggestedAdditionalQty > 0) ? suggestedAdditionalQty : '-'
    return <div style={{ color: color, width: '100%', textAlign: 'right' }}>
        <Typography variant="body2" >{displayVal}</Typography>
    </div>
}

const SuggestedAdditionalQtyProvider = () => {
    return <StringTypeProvider name="suggestedAdditionalQty" align="center" Formatter={SuggestedAdditionalQtyFormatter} />
}

const ReviewButtion = ({ orderCalcNo, selections, data }: {
    orderCalcNo?: string,
    selections: number[],
    data: OrderCalculationReviewResult[]
}) => {
    const [open, setOpen] = useState<boolean>(false)
    const [reviewReason, setReviewReason] = useState<{ adjustmentReason?: string }>({})
    const [messages, setMessages] = useState<Message[]>([])
    const intl = useIntl()
    const fields = useMemo(() => ({
        adjustmentReason: { labelId: 'adjustmentReason', required: true, length: { max: 200 } },
    }), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const formValidate = useFormValidater(setMessages, fields)
    const navigate = useNavigate()
    const [disabled, setDisabled] = useState<boolean>(false)
    const title = useMemo(() => intl.formatMessage({ id: 'review' }), [intl])

    const dispatch = useDispatch()
    const onIconClik = (() => {
        if (selections.length <= 0) {
            //Please select at least one order to review.
            dispatch(applicationActions.pushError({
                title: { code: 'review' },
                messages: { code: 'w0002' }
            }))
            return
        }
        setOpen(true)
    })

    const reviewOrderCalculationByCondition = useReviewOrderCalculationByCondition()
    const functionStore = useFunctionStore()
    const saveData = useCallback(() => {
        if (formValidate(reviewReason)) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                setOpen(false)
                reviewOrderCalculationByCondition({
                    orderCalcNo: orderCalcNo,
                    adjustmentReason: reviewReason.adjustmentReason,
                    orderCalcPartsIds: selections
                }, { serialized: true }).then((result: any) => {
                    navigate(`/orderCalculation/placeOrderSpot-${result.poGroupId}`)
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: { code: 'review' },
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL',
                }, {
                    label: 'CONFIRM',
                    functionId
                }]
            }))
        }
    }, [dispatch, formValidate, functionStore, navigate, orderCalcNo, reviewOrderCalculationByCondition, reviewReason, selections, title])

    return <Access access="STCK.OCCLS016.REVIEW">
        <CallbackViewAction title={<FormattedMessage id="review" />} callback={onIconClik} />
        <DarkDialog open={open} style={{ overflow: 'hidden' }} fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="Review Order Calculation" />
            </DialogHeader>
            <DialogContent>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="method" name="method" value={reviewReason} >
                        <FormattedMessage id="confirmReviewOrderCalculation" />
                        <Form data={reviewReason} setData={setReviewReason} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500} messages={messages} setMessages={setMessages} ensure={filedCheck}>
                            <StringItem field="adjustmentReason" required label={intl.formatMessage({ id: 'reason' })} />
                        </Form>
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={saveData} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </Access>
}

