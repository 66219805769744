import moment from "moment"
import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { BasicForm } from "./BasicForm"
import { CalculateAction } from "./CalculateAction"
import { CustUsageTable } from "./CustUsageTable"
import { occls013Actions } from "./OCCLS013Slice"

export function OCCLS013() {
    const { orderGroupId, orderTime, orderCalcNo } = useParams()
    const dispatch = useDispatch()
    const companyType = useGetCompanyType()

    useEffect(() => {
        // load data
        dispatch(occls013Actions.setType('default'))
        if (orderTime) {
            dispatch(occls013Actions.setOrderTime(moment(orderTime, 'YYYYMM').toDate()))
        }
        if (CbdsType.BU === companyType) {
            dispatch(occls013Actions.getBuyerOptions())
        } else {
            dispatch(occls013Actions.getSellerOptions())
        }
        dispatch(occls013Actions.createView({
            orderGroupId: orderGroupId || undefined,
            orderCalcNo: orderCalcNo || undefined,
        }))
        // destroy state after unmount
        return () => {
            dispatch(occls013Actions.unmount())
        }
    }, [companyType, dispatch, orderCalcNo, orderGroupId, orderTime])

    const intl = useIntl()
    return <View actions={<CalculateAction />}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'BasicInfo' })}
                subtitle={intl.formatMessage({ id: 'basicInfo' })}
            />
            <SectionCardContent>
                <BasicForm />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'usage' })}
            />
            <SectionCardContent>
                <CustUsageTable />
            </SectionCardContent>
        </SectionCard>
    </View>
}