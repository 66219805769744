import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Detail, Expanding, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import { useIntl } from "react-intl"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { OrderCalculationListViewResult } from "../../../services/smt/models/OrderCalculationListViewResult"
import { today } from "../../../utils/ApplicationUtils"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { CreateRowAction } from "./CreateRowAction"
import { DeleteRowAction } from "./DeleteRowAction"
import { DetailTable } from "./DetailTable"
import { DownloadAction } from "./DownloadAction"
import { ExpandCell } from "./ExpandCell"
import { Filters } from "./Filters"
import { ListTableSelection } from "./ListTableSelection"
import { MonthTypeProvider } from "./MonthTypeProvider"
import { useOCCLS010Selector } from "./OCCLS010Slice"
import { PlaceOrderRowAction } from "./PlaceOrderRowAction"
import { RecalculationRowAction } from "./RecalculationRowAction"
import { ReviewRowAction } from "./ReviewRowAction"
import { StatusTypeProvider } from "./StatusTypeProvider"
import { UploadAction } from "./UploadAction"
import { ViewRowAction } from "./ViewRowAction"
import { WithdrawRowAction } from "./WithdrawRowAction"

export interface ListTableRow {
  /* Metadata */
  rowId: string,
  orderGroupId: string,
  inCurrentMonth: boolean,
  orderCalcId?: string,
  orderCalcCount: number,
  orderVersion?: number,
  maxOrderVersion?: number,
  poGroupId?: string,
  partialPlaceOrder: boolean,
  /* Data */
  orderGroupNo?: string,
  customerCode?: string,
  orderFrequency?: number,
  orderMonth?: Date,
  orderWeek?: string,
  status?: number,
  orderCalcNo?: string,
  usageVersion?: string,
  cutoffDate?: Date,
  createdDate?: Date | null,
  createdBy?: string,
  updatedDate?: Date | null,
  updatedBy?: string,
  remark?: string,
  buyerCode?: string,
  sellerCode?: string
}

export function ListTable() {
  const type = useOCCLS010Selector(state => state.type)

  const monthFormatter = Intl.DateTimeFormat(undefined, { year: 'numeric', month: '2-digit' })
  const currentYearMonth = monthFormatter.format(today())
  const rows = useOCCLS010Selector<ListTableRow[]>(state => {
    const buyerOptions = state.buyerOptions
    const results = state.results
    const maxOrderVersionMap: Record<string, number> = {}
    for (const result of results) {
      const orderGroup = result.orderGroup
      const orderGroupId = orderGroup.orderGroupId
      const orderYearMonth = monthFormatter.format(result.orderTime)
      if (result.orderCalcs && result.orderCalcs.length > 0) {
        const maxOrderVersion = result.orderCalcs.map(oc => oc.orderVersion).reduce((a, b) => Math.max(a, b), 0)
        const key = `${orderGroupId}-${orderYearMonth}`
        if (maxOrderVersionMap[key] !== undefined) {
          maxOrderVersionMap[key] = Math.max(maxOrderVersionMap[key], maxOrderVersion)
        } else {
          maxOrderVersionMap[key] = maxOrderVersion
        }
      }
    }
    function maxOrderVersion(result: OrderCalculationListViewResult) {
      return maxOrderVersionMap[`${result.orderGroup.orderGroupId}-${monthFormatter.format(result.orderTime)}`]
    }
    const poGroupIdMap: Record<string, (string | undefined)[]> = {}
    for (const result of results) {
      if (!result.orderCalcs) {
        continue
      }
      for (const orderCalc of result.orderCalcs) {
        const key = orderCalc.orderCalcNo
        const poGroupId = orderCalc.poGroupId
        if (!poGroupIdMap[key]) {
          poGroupIdMap[key] = []
        }
        if (!poGroupIdMap[key].includes(poGroupId)) {
          poGroupIdMap[key].push(poGroupId)
        }
      }
    }
    function partialPlaceOrder(result: OrderCalculationListViewResult) {
      const orderCalcNo = result.orderCalcs?.[0]?.orderCalcNo
      if (orderCalcNo) {
        const poGroupIds = poGroupIdMap[orderCalcNo]
        return poGroupIds.includes(undefined) && poGroupIds.length > 1
      } else {
        return false
      }
    }

    return results.map(result => {
      const orderGroup = result.orderGroup
      const orderCalcs = result.orderCalcs
      const firstOrderCalc = orderCalcs && orderCalcs.length > 0 ? orderCalcs[0] : undefined
      const row = {
        /* Metadata */
        rowId: `${orderGroup.orderGroupId}-${result.orderTime.getTime()}-${firstOrderCalc?.orderCalcId}`,
        orderGroupId: orderGroup.orderGroupId,
        inCurrentMonth: currentYearMonth === monthFormatter.format(result.orderTime),
        orderCalcId: firstOrderCalc?.orderCalcId,
        orderCalcCount: orderCalcs ? orderCalcs.length : 0,
        orderVersion: firstOrderCalc?.orderVersion,
        maxOrderVersion: maxOrderVersion(result),
        poGroupId: firstOrderCalc?.poGroupId,
        partialPlaceOrder: partialPlaceOrder(result),
        /* Data */
        orderGroupNo: orderGroup.orderGroupNo,
        customerCode: buyerOptions[((orderGroup.customerId ?? '') + '-1') as unknown as number],
        orderFrequency: orderGroup.orderFrequency,
        orderMonth: result.orderTime,
        orderWeek: '',
        status: firstOrderCalc ? Math.min(...orderCalcs.map(oc => oc.status!)) : OrderCalcStatus.PENDING_CALCULATION,
        orderCalcNo: firstOrderCalc?.orderCalcNo,
        usageVersion: firstOrderCalc?.usageVersion,
        cutoffDate: firstOrderCalc?.cutoffDate,
        createdDate: firstOrderCalc?.createdDate ?? null,
        createdBy: firstOrderCalc?.createdBy,
        updatedDate: firstOrderCalc?.updatedDate ?? null,
        updatedBy: firstOrderCalc?.updatedBy,
        remark: firstOrderCalc?.remark,
      }
      return row
    })
  })

  const intl = useIntl()
  const { getCodeName } = useGetCodeName()
  const columns = [
    { field: 'orderGroupNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderGroupNo' }), width: 200 },
    { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 },
    { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
    { field: 'orderMonth', dataTypeName: 'month', title: intl.formatMessage({ id: 'field.orderMonth' }), width: 180 },
    { field: 'orderWeek', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderWeek' }), width: 180 },
    { field: 'status', dataTypeName: 'status', title: intl.formatMessage({ id: 'field.status' }), width: 200, getCellValue: (row: Row) => getCodeName(CodeCategory.OrderCalcStatus, row.status ?? undefined) },
    { field: 'orderCalcNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.orderCalcNo' }), width: 250 },
    { field: 'usageVersion', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.usageVersion' }), width: 200 },
    { field: 'cutoffDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.cutoffDate' }), width: 200 },
    { field: 'createdDate', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
    { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.createdBy' }), width: 200 },
    { field: 'updatedDate', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },
    { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 200 },
    { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 250 },
  ] as Column[]

  return <DataGrid>
    <ToolbarLayout />
    <TableLayout Container={FlexScrollbar}>
      <TableHeaderLayout sticky />
      <TableBodyLayout />
    </TableLayout>
    <PaginationLayout Pagination={Pagination} />
    <DataTypePreset />
    <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
    <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderCalcStatus} />
    <StatusTypeProvider name="status" />
    <MonthTypeProvider name="month" />
    <Data rows={rows} columns={columns} getRowId={row => row.rowId} />
    <ToolbarActionProvider Action={UploadAction} actionProps={{ type }} />
    <ToolbarActionProvider Action={DownloadAction} actionProps={{ type }} />
    <RowActionProvider name="create" Action={CreateRowAction} actionProps={{ type }} />
    <RowActionProvider name="recalculation" Action={RecalculationRowAction} actionProps={{ type }} />
    <RowActionProvider name="view" Action={ViewRowAction} actionProps={{ type }} />
    <RowActionProvider name="review" Action={ReviewRowAction} actionProps={{ type }} />
    <RowActionProvider name="placeOrder" Action={PlaceOrderRowAction} actionProps={{ type }} />
    <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={{ type }} />
    <RowActionProvider name="withdraw" Action={WithdrawRowAction} actionProps={{ type }} />
    <ColumnFreeze />
    <ColumnVisibility
      defaultHiddenFields={['orderWeek', 'createdDate', 'createdBy', 'updatedDate', 'updatedBy']}
      columnSettings={{
        orderCalcNo: { disableUserControl: true },
        orderGroupNo: { disableUserControl: true },
        usageVersion: { disableUserControl: true },
        customerCode: { disableUserControl: true },
      }} ToolbarButton={ColumnVisibilityToolbarButton} />
    <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
    <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
    <Searching ignoreCase Input={SearchInput} />
    <ToolbarItemProvider Item={Filters} />
    <Detail DetailFormatter={DetailTable} />
    <Sorting />
    <Filtering />
    <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    <ListTableSelection />
    <Action width={250} />
    <Expanding BodyExpandCell={ExpandCell} />
  </DataGrid>
}