import { Route, Routes } from "react-router-dom"
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { INT050 } from "../layouts/integration/INT050/INT050"
import { INT060 } from "../layouts/integration/INT060/INT060"
import { NotFound } from "../layouts/NotFound/NotFound"
import { CSUGS120 } from "../layouts/smt/CSUGS120/CSUGS120"
import { CSUGS130 } from "../layouts/smt/CSUGS130/CSUGS130"
import { CSUGS131 } from "../layouts/smt/CSUGS131/CSUGS131"
import { CSUHS110 } from "../layouts/smt/CSUHS110/CSUHS110"
import { CSUHS120 } from "../layouts/smt/CSUHS120/CSUHS120"
import { CSUHS140 } from "../layouts/smt/CSUHS140/CSUHS140"
import { CSUHS150 } from "../layouts/smt/CSUHS150/CSUHS150"
import { MSAUF010 } from "../layouts/smt/MSAUF010/MSAUF010"
import { MSAUF011 } from "../layouts/smt/MSAUF011/MSAUF011"
import { MSAUF020 } from "../layouts/smt/MSAUF020/MSAUF020"
import { MSAUF021 } from "../layouts/smt/MSAUF021/MSAUF021"
import { MSOGS010 } from "../layouts/smt/MSOGS010/MSOGS010"
import { MSOGS020 } from "../layouts/smt/MSOGS020/MSOGS020"
import { OCCLS010 } from "../layouts/smt/OCCLS010/OCCLS010"
import { OCCLS010Ehm } from "../layouts/smt/OCCLS010/OCCLS010Ehm"
import { OCCLS010PNA } from "../layouts/smt/OCCLS010/OCCLS010PNA"
import { OCCLS013 } from "../layouts/smt/OCCLS013/OCCLS013"
import { OCCLS013Ehm } from "../layouts/smt/OCCLS013/OCCLS013Ehm"
import { OCCLS013EhmBySeller } from "../layouts/smt/OCCLS013/OCCLS013EhmBySeller"
import { OCCLS013PNA } from "../layouts/smt/OCCLS013/OCCLS013PNA"
import { OCCLS014 } from "../layouts/smt/OCCLS014/OCCLS014"
import { OCCLS015 } from "../layouts/smt/OCCLS015/OCCLS015"
import { OCCLS015Ehm } from "../layouts/smt/OCCLS015/OCCLS015Ehm"
import { OCCLS015PNA } from "../layouts/smt/OCCLS015/OCCLS015PNA"
import { OCCLS016 } from "../layouts/smt/OCCLS016/OCCLS016"
import { OCCLS017 } from "../layouts/smt/OCCLS017/OCCLS017"
import { OCCLS017Ehm } from "../layouts/smt/OCCLS017/OCCLS017Ehm"
import { OCCLS017PNA } from "../layouts/smt/OCCLS017/OCCLS017PNA"
import { SMGTS110 } from "../layouts/smt/SMGTS110/SMGTS110"
import { SMGTS210 } from "../layouts/smt/SMGTS210/SMGTS210"
import { SMGTS211 } from "../layouts/smt/SMGTS211/SMGTS211"
import { SMGTS310 } from "../layouts/smt/SMGTS310/SMGTS310"
import { SMGTS410 } from "../layouts/smt/SMGTS410/SMGTS410"
import { SMGTS610 } from "../layouts/smt/SMGTS610/SMGTS610"
import { SMGTS710 } from "../layouts/smt/SMGTS710/SMGTS710"
import { SMGTS710MAPA } from "../layouts/smt/SMGTS710MAPA/SMGTS710MAPA"
import { SMGTS810 } from "../layouts/smt/SMGTS810/SMGTS810"
import { SMGTS810MAPA } from "../layouts/smt/SMGTS810MAPA/SMGTS810MAPA"
import { VSRPS010 } from "../layouts/smt/VSRPS010/VSRPS010"
import { BTETL010 } from "../layouts/tool/BTETL010/BTETL010"
import { TOLS010 } from "../layouts/tool/TOLS010/TOLS010"

export function SmtRoutes() {
    return <Routes>

        <Route path="/batch" element={<AuthNavigate access="STCK.BTETL010.VIEW"><BTETL010 /></AuthNavigate>} />,
        <Route path="/tools" element={<AuthNavigate access="TOOL.TOLS010.VIEW"><TOLS010 /></AuthNavigate>} />,

        <Route path="/weeklyRundown" element={<AuthNavigate access="STCK.SMGTS110.VIEW"><SMGTS110 /></AuthNavigate>} />,
        <Route path="/weeklyRundown/dailyRundown" element={<AuthNavigate access="STCK.SMGTS211.VIEW"><SMGTS211 /></AuthNavigate>} />,
        <Route path="/dailyRundownList" element={<AuthNavigate access="STCK.SMGTS210.VIEW"><SMGTS210 /></AuthNavigate>} />,
        <Route path="/dailyRundownList/dailyRundown" element={<AuthNavigate access="STCK.SMGTS211.VIEW"><SMGTS211 /></AuthNavigate>} />,
        <Route path="/pokayoke" element={<AuthNavigate access="STCK.VSRPS010.VIEW"><VSRPS010 /></AuthNavigate>} />,

        <Route path="/customerStockList" element={<AuthNavigate access="STCK.SMGTS310.VIEW"><SMGTS310 /></AuthNavigate>} />,
        <Route path="/stockAdjustmentList" element={<AuthNavigate access="STCK.SMGTS410.VIEW"><SMGTS410 /></AuthNavigate>} />,
        <Route path="/impInventoryHistoryList" element={<AuthNavigate access="STCK.SMGTS610.VIEW"><SMGTS610 /></AuthNavigate>} />,
        <Route path="/incoming" element={<AuthNavigate access="STCK.SMGTS710.VIEW"><SMGTS710 /></AuthNavigate>} />,
        <Route path="/outgoing" element={<AuthNavigate access="STCK.SMGTS810.VIEW"><SMGTS810 /></AuthNavigate>} />,
        <Route path="/incomingMapa" element={<AuthNavigate access="STCK.SMGTS710MAPA.VIEW"><SMGTS710MAPA /></AuthNavigate>} />,
        <Route path="/outgoingMapa" element={<AuthNavigate access="STCK.SMGTS810MAPA.VIEW"><SMGTS810MAPA /></AuthNavigate>} />,
        <Route path="/bcro" element={<AuthNavigate access="INT.INT050.VIEW"><INT050 /></AuthNavigate>} />,
        <Route path="/bccHistory" element={<AuthNavigate access="INT.INT060.VIEW"><INT060 /></AuthNavigate>} />,

        <Route path="/cuDownloadUpload" element={<AuthNavigate access="STCK.CSUGS120.VIEW"><CSUGS120 /></AuthNavigate>} />,
        <Route path="/project/create" element={<AuthNavigate access="STCK.CSUGS131.VIEW"><CSUGS131 /></AuthNavigate>} />,
        <Route path="/project/modify-:projectId" element={<AuthNavigate access="STCK.CSUGS131.VIEW"><CSUGS131 /></AuthNavigate>} />,
        <Route path="/project/view-:projectId" element={<AuthNavigate access="STCK.CSUGS131.VIEW"><CSUGS131 /></AuthNavigate>} />,
        <Route path="/project" element={<AuthNavigate access="STCK.CSUGS130.VIEW"><CSUGS130 /></AuthNavigate>} />,

        <Route path="/actualUsage" element={<AuthNavigate access="STCK.CSUHS140.VIEW"><CSUHS140 /></AuthNavigate>} />,
        <Route path="/customerActualUsage" element={<AuthNavigate access="STCK.CSUHS150.VIEW"><CSUHS150 /></AuthNavigate>} />,

        <Route path="/usageHistoryParentLandingpg" element={<AuthNavigate access="STCK.CSUHS110.VIEW"><CSUHS110 /></AuthNavigate>} />,
        <Route path="/usageHistory" element={<AuthNavigate access="STCK.CSUHS120.VIEW"><CSUHS120 /></AuthNavigate>} />,

        <Route path="/orderCalculationGrouping" element={<AuthNavigate access="STCK.MSOGS010.VIEW"><MSOGS010 /></AuthNavigate>} />,
        <Route path="/orderCalculationGroupingEhm" element={<AuthNavigate access="STCK.MSOGS020.VIEW"><MSOGS020 /></AuthNavigate>} />,

        <Route path="/alarmUsagePattern/view-:customerPartsId" element={<AuthNavigate access="STCK.MSAUF011.VIEW"><MSAUF011 /></AuthNavigate>} />,
        <Route path="/alarmUsagePattern/edit-:customerPartsId" element={<AuthNavigate access="STCK.MSAUF011.VIEW"><MSAUF011 /></AuthNavigate>} />,
        <Route path="/alarmUsagePattern" element={<AuthNavigate access="STCK.MSAUF010.VIEW"><MSAUF010 /></AuthNavigate>} />,

        <Route path="/stockManagementMaster/view-:customerPartsId" element={<AuthNavigate access="STCK.MSAUF021.VIEW" ><MSAUF021 /></AuthNavigate>} />,
        <Route path="/stockManagementMaster/edit-:customerPartsId" element={<AuthNavigate access="STCK.MSAUF021.VIEW" ><MSAUF021 /></AuthNavigate>} />,
        <Route path="/stockManagementMaster" element={<AuthNavigate access="STCK.MSAUF020SM.VIEW"><MSAUF020 /></AuthNavigate>} />,

        <Route path="/orderCalculationMaster/view-:customerPartsId" element={<AuthNavigate access="STCK.MSAUF021.VIEW"><MSAUF021 /></AuthNavigate>} />,
        <Route path="/orderCalculationMaster/edit-:customerPartsId" element={<AuthNavigate access="STCK.MSAUF021.VIEW"><MSAUF021 /></AuthNavigate>} />,
        <Route path="/orderCalculationMaster" element={<AuthNavigate access="STCK.MSAUF020OC.VIEW"><MSAUF020 /></AuthNavigate>} />,

        <Route path="/stockManagementOrderCalcMaster/view-:customerPartsId" element={<AuthNavigate access="STCK.MSAUF021.VIEW"><MSAUF021 /></AuthNavigate>} />,
        <Route path="/stockManagementOrderCalcMaster/edit-:customerPartsId" element={<AuthNavigate access="STCK.MSAUF021.VIEW"><MSAUF021 /></AuthNavigate>} />,
        <Route path="/stockManagementOrderCalcMaster" element={<AuthNavigate access="STCK.MSAUF020SMOC.VIEW"><MSAUF020 /></AuthNavigate>} />,

        <Route path="/orderCalculation/placeOrderSpot-:poGroupId" element={<AuthNavigate access="STCK.OCCLS017.VIEW"><OCCLS017 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/placeOrderSpot-:poGroupId" element={<AuthNavigate access="STCK.OCCLS017.VIEW"><OCCLS017PNA /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/placeOrderSpot-:poGroupId" element={<AuthNavigate access="STCK.OCCLS017.VIEW"><OCCLS017Ehm /></AuthNavigate>} />,
        <Route path="/orderCalculation/placeOrderSpotView-:poGroupId" element={<AuthNavigate access="STCK.OCCLS017.VIEW"><OCCLS017 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/placeOrderSpotView-:poGroupId" element={<AuthNavigate access="STCK.OCCLS017.VIEW"><OCCLS017PNA /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/placeOrderSpotView-:poGroupId" element={<AuthNavigate access="STCK.OCCLS017.VIEW"><OCCLS017Ehm /></AuthNavigate>} />,

        <Route path="/orderCalculation/editSpot-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS016.VIEW"><OCCLS016 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/editSpot-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS016.VIEW"><OCCLS016 /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/editSpotForBuyer-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS016.VIEW"><OCCLS016 /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/editSpotForSeller-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS016.VIEW"><OCCLS016 /></AuthNavigate>} />,
        <Route path="/orderCalculation/viewSpot-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS016.VIEW"><OCCLS016 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/viewSpot-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS016.VIEW"><OCCLS016 /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/viewSpotForBuyer-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS016.VIEW"><OCCLS016 /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/viewSpotForSeller-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS016.VIEW"><OCCLS016 /></AuthNavigate>} />,

        <Route path="/orderCalculation/placeOrderRegular-:poGroupId" element={<AuthNavigate access="STCK.OCCLS015.VIEW"><OCCLS015 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/placeOrderRegular-:poGroupId" element={<AuthNavigate access="STCK.OCCLS015.VIEW"><OCCLS015PNA /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/placeOrderRegular-:poGroupId" element={<AuthNavigate access="STCK.OCCLS015.VIEW"><OCCLS015Ehm /></AuthNavigate>} />,
        <Route path="/orderCalculation/placeOrderRegularView-:poGroupId" element={<AuthNavigate access="STCK.OCCLS015.VIEW"><OCCLS015 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/placeOrderRegularView-:poGroupId" element={<AuthNavigate access="STCK.OCCLS015.VIEW"><OCCLS015PNA /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/placeOrderRegularView-:poGroupId" element={<AuthNavigate access="STCK.OCCLS015.VIEW"><OCCLS015Ehm /></AuthNavigate>} />,

        <Route path="/orderCalculation/editRegular-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/editRegular-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/editRegular-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,
        <Route path="/orderCalculation/viewRegular-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/viewRegular-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/viewRegular-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,

        <Route path="/orderCalculationEhm/viewRegularForBuyer-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/viewRegularForSeller-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/editRegularForBuyer-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/editRegularForSeller-:orderCalcId" element={<AuthNavigate access="STCK.OCCLS014.VIEW"><OCCLS014 /></AuthNavigate>} />,

        <Route path="/orderCalculation/createCalculate-:orderGroupId-:orderTime" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/createCalculate-:orderGroupId-:orderTime" element={<AuthNavigate access="STCK.OCCLS013.VIEW" ><OCCLS013PNA /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/createCalculate-:orderGroupId-:orderTime" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013Ehm /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/createCalculateBySeller-:orderGroupId-:orderTime" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013EhmBySeller /></AuthNavigate>} />,
        <Route path="/orderCalculation/modifyCalculate-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/modifyCalculate-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013PNA /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/modifyCalculate-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013Ehm /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/modifyCalculateBySeller-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013EhmBySeller /></AuthNavigate>} />,
        <Route path="/orderCalculation/reviewCalculate-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA/reviewCalculate-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013PNA /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/reviewCalculate-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013Ehm /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm/reviewCalculateBySeller-:orderCalcNo" element={<AuthNavigate access="STCK.OCCLS013.VIEW"><OCCLS013EhmBySeller /></AuthNavigate>} />,

        <Route path="/orderCalculation" element={<AuthNavigate access="STCK.OCCLS010.VIEW"><OCCLS010 /></AuthNavigate>} />,
        <Route path="/orderCalculationPNA" element={<AuthNavigate access="STCK.OCCLS010PNA.VIEW"><OCCLS010PNA /></AuthNavigate>} />,
        <Route path="/orderCalculationEhm" element={<AuthNavigate access="STCK.OCCLS010SMT.VIEW"><OCCLS010Ehm /></AuthNavigate>} />,

        <Route path="*" element={<NotFound scope="smt" />} />,
    </Routes >
}