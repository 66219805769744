import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetOrderCalculationPageForEhm, useGetOrderGroupPeopleInfoForEhm } from "../../../services/smt/api/OrderCalculationApi"
import { OrderCalculationTransfer } from "../../../services/smt/models/OrderCalculationTransfer"
import { OrderCalculationView, orderCalculationViewFromJson, orderCalculationViewToJson } from "../../../services/smt/models/OrderCalculationView"
import type { OrderGroupPeopleInfoResult } from "../../../services/smt/models/OrderGroupPeopleInfoResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MSOGS020PcUi } from "./MSOGS020PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCachekey = sessionKeys.Filters_MSOGS020

export const MSOGS020 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? orderCalculationViewFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<OrderCalculationView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<OrderCalculationTransfer[]>([])

    const [totalCount, setTotalCount] = useState<number>(0)
    const getOrderCalculationPageForEhm = useGetOrderCalculationPageForEhm()
    const search = useCallback((filters: OrderCalculationView) => {
        getOrderCalculationPageForEhm(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(orderCalculationViewToJson(filters)))
        })
    }, [getOrderCalculationPageForEhm])

    const [peopleInfo, setPeopleInfo] = useState<OrderGroupPeopleInfoResult>({})
    const getOrderGroupPeopleInfo = useGetOrderGroupPeopleInfoForEhm()

    const getPeopleInfo = useCallback(() => {
        getOrderGroupPeopleInfo(undefined, { silent: true, serialized: true }).then(result => {
            setPeopleInfo(result || {})
        })
    }, [getOrderGroupPeopleInfo])

    useEffect(() => {
        getPeopleInfo()
    }, [getPeopleInfo])

    useSearchOnLoad(search, filters)

    return <MSOGS020PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
        peopleInfo={peopleInfo}
        getPeopleInfo={getPeopleInfo}
    />
}