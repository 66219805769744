import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core"
import { EntryItem, Form, NumberItem, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { SetStateAction, memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { useFunctionStore } from "../../../Root"
import { SubmitCallbackViewAction } from "../../../components/Action/SubmitCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { InventoryBoxFlag } from "../../../services/master/enums/InventoryBoxFlag"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { useSaveOrderCalculationMasterDetail, useSaveStockManagementAndOrderCalculationMasterDetail, useSaveStockManagementMasterDetail } from "../../../services/smt/api/StockManagementMasterApi"
import { OdmCustomerPartsOrder } from "../../../services/smt/models/AlarmUsagePatternDetail"
import { OrderGroupTransfer } from "../../../services/smt/models/OrderGroupTransfer"
import { OdmCustomerParts, OdmCustomerPartsControl, StockManagementMasterDetail } from "../../../services/smt/models/StockManagementMasterDetail"
import { applicationActions } from "../../Application/applicationSlice"
import { StockOrderScreenMode } from "../MSAUF020/MSAUF020"
import { ConsolidationType } from "./MSAUF021"

interface MSAUF021PcUiProps {
    odmCustomerParts: OdmCustomerParts,
    setOdmCustomerParts: React.Dispatch<SetStateAction<OdmCustomerParts>>,
    odmCustomerPartsControl: OdmCustomerPartsControl,
    setOdmCustomerPartsControl: React.Dispatch<SetStateAction<OdmCustomerPartsControl>>,
    odmCustomerPartsOrder: OdmCustomerPartsOrder,
    setOdmCustomerPartsOrder: React.Dispatch<SetStateAction<OdmCustomerPartsOrder>>,
    mode: ScreenMode,
    search: (customerPartsId: number | null) => void,
    orderGroupList: OrderGroupTransfer[],
    uoms: TnmUom[]
    display: StockOrderScreenMode
}

export const MSAUF021PcUi = (props: MSAUF021PcUiProps) => {
    const intl = useIntl()

    const { mode, odmCustomerParts, odmCustomerPartsControl, odmCustomerPartsOrder, display } = props
    const [dialog, setDialog] = useState<boolean>(false)

    const actions = mode === ScreenMode.VIEW ? [] : [
        <SubmitAction odmCustomerParts={odmCustomerParts} odmCustomerPartsControl={odmCustomerPartsControl} odmCustomerPartOrder={odmCustomerPartsOrder} display={display} setDialog={setDialog} />
    ]
    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: display === StockOrderScreenMode.ALLPATTERN ? 'customerPartsInfo' : 'buyerPartsInfo' })}
                subtitle={intl.formatMessage({ id: display === StockOrderScreenMode.ALLPATTERN ? 'basicCustomerPartsInfoSub' : 'basicBuyerPartsInfoSub' })}
            />
            <SectionCardContent>
                <CustomerPartsFormView {...props} />
            </SectionCardContent>
        </SectionCard>
        {display !== StockOrderScreenMode.ONLYOC ?
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'stockAlarmInfo' })}
                    subtitle={intl.formatMessage({ id: 'stockAlarmInfoSub' })}
                />
                <SectionCardContent>
                    <StockAlarmFormView {...props} />
                </SectionCardContent>
            </SectionCard> : <></>}
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={display === StockOrderScreenMode.ONLYOC ? 2 : 3}
                title={intl.formatMessage({ id: 'usagePatternInfo' })}
                subtitle={intl.formatMessage({ id: 'usagePatternInfoSub' })}
            />
            <SectionCardContent>
                <UsagePatternFormView {...props} />
            </SectionCardContent>
        </SectionCard>
        {display !== StockOrderScreenMode.ONLYSMT ?
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={display === StockOrderScreenMode.ONLYOC ? 3 : 4}
                    title={intl.formatMessage({ id: 'orderCalculationInfo' })}
                    subtitle={intl.formatMessage({ id: 'orderCalculationInfoSub' })}
                />
                <SectionCardContent>
                    <OrderCalculationFormView {...props} />
                </SectionCardContent>
            </SectionCard> : <></>}
        <WaringMessageDialog dialog={dialog} setDialog={setDialog} display={display} />
    </View>
}

const CustomerPartsFormView = ({ odmCustomerParts, setOdmCustomerParts, mode, uoms }: MSAUF021PcUiProps) => {
    const intl = useIntl()
    const readOnly = mode === ScreenMode.VIEW
    const uomMap: [string, string][] = useMemo(() => uoms.map(m => [m.uomCode, m.uomCode]), [uoms])

    return <Form data={odmCustomerParts} setData={setOdmCustomerParts} labelDisplay="block" helperDisplay="tooltip">
        <StringItem field="partsNo" required readonly={true} label={intl.formatMessage({ id: 'field.partsNo' })} />
        <StringItem field="externalCustomerCode" required readonly={true} label={intl.formatMessage({ id: 'field.buyerCode' })} />
        <StringItem field="externalRefNo" required readonly={true} label={intl.formatMessage({ id: 'field.externalRefNo' })} />

        <StringItem field="customerPartsNo" required readonly={true} label={intl.formatMessage({ id: 'field.buyerPartsNo' })} />
        <StringItem field="customerPartsName" required readonly={readOnly} label={intl.formatMessage({ id: 'field.buyerPartsName' })} />
        <StringItem field="customerBackNo" readonly={readOnly} label={intl.formatMessage({ id: 'field.backNo' })} />

        <StringItem field="grouping" readonly={readOnly} label={intl.formatMessage({ id: 'grouping' })} />
        <StringItem field="commonPartsNo" readonly={readOnly} label={intl.formatMessage({ id: 'field.commonPartsNo' })} />
        <StringItem field="partsRemark" readonly={readOnly} label={intl.formatMessage({ id: 'field.specialPartsType' })} />

        <EntryItem field="uomCode" required readonly={readOnly} label={intl.formatMessage({ id: 'field.uomCode' })} entries={uomMap} />
        <CodeItem field="activeFlag" required readonly={readOnly} label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.SmtActiveFlag} />
    </Form >
}

const StockAlarmFormView = ({ odmCustomerPartsControl, setOdmCustomerPartsControl, mode }: MSAUF021PcUiProps) => {
    const intl = useIntl()
    const readOnly = mode === ScreenMode.VIEW
    const readOnly1 = mode === ScreenMode.VIEW || odmCustomerPartsControl.inventoryBoxFlag === InventoryBoxFlag.Y
    const readOnly2 = mode === ScreenMode.VIEW || odmCustomerPartsControl.inventoryBoxFlag === InventoryBoxFlag.N

    const dataChange = useCallback<React.Dispatch<React.SetStateAction<OdmCustomerPartsControl>>>(nextDraftDataFunc => {
        setOdmCustomerPartsControl(data => {
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(data) : nextDraftDataFunc
            if (draftData) {
                if (draftData.inventoryBoxFlag === InventoryBoxFlag.Y) {
                    return { ...draftData, minDays1: undefined, minDays2: undefined, minDays3: undefined, maxDays: undefined }
                } else if (draftData.inventoryBoxFlag === InventoryBoxFlag.N) {
                    return { ...draftData, minBoxes1: undefined, minBoxes2: undefined, minBoxes3: undefined, maxBoxes: undefined }
                } else {
                    return { ...draftData, minDays1: undefined, minDays2: undefined, minDays3: undefined, maxDays: undefined, minBoxes1: undefined, minBoxes2: undefined, minBoxes3: undefined, maxBoxes: undefined }
                }
            }
            return draftData;
        })
    }, [setOdmCustomerPartsControl])

    return <Form data={odmCustomerPartsControl} setData={dataChange} labelDisplay="block" helperDisplay="tooltip">
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="basicInfo" /></SectionTitle></GridItem>
        <CodeItem field="inventoryBoxFlag" required readonly={readOnly} label={intl.formatMessage({ id: 'inventoryBoxFlag' })} code={CodeCategory.InventoryBoxFlag} />
        <NumberItem field="outboundFluctuation" readonly={readOnly} label={intl.formatMessage({ id: 'outboundFluctuation' })} />
        <CodeItem field="onshippingDelayPat" readonly={readOnly} label={intl.formatMessage({ id: 'onshippingDelayPat' })} code={CodeCategory.OnShippingDelayPattern} />
        <CodeItem field="alertType" readonly={readOnly} label={intl.formatMessage({ id: 'field.alertType' })} code={CodeCategory.AlertMethod} />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="stockDays" /></SectionTitle></GridItem>
        <NumberItem field="minDays1" readonly={readOnly1} label={intl.formatMessage({ id: 'minStockDays1' })} />
        <NumberItem field="minDays2" readonly={readOnly1} label={intl.formatMessage({ id: 'minStockDays2' })} />
        <NumberItem field="minDays3" readonly={readOnly1} label={intl.formatMessage({ id: 'minStockDays3' })} />
        <NumberItem field="maxDays" readonly={readOnly1} label={intl.formatMessage({ id: 'maxStockDays' })} />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="stockBoxes" /></SectionTitle></GridItem>
        <NumberItem field="minBoxes1" readonly={readOnly2} label={intl.formatMessage({ id: 'minStockBoxes1' })} />
        <NumberItem field="minBoxes2" readonly={readOnly2} label={intl.formatMessage({ id: 'minStockBoxes2' })} />
        <NumberItem field="minBoxes3" readonly={readOnly2} label={intl.formatMessage({ id: 'minStockBoxes3' })} />
        <NumberItem field="maxBoxes" readonly={readOnly2} label={intl.formatMessage({ id: 'maxStockBoxes' })} />
    </Form >
}

const UsagePatternFormView = ({ odmCustomerPartsControl, setOdmCustomerPartsControl, mode }: MSAUF021PcUiProps) => {
    const intl = useIntl()
    const readOnly = mode === ScreenMode.VIEW

    return <Form data={odmCustomerPartsControl} setData={setOdmCustomerPartsControl} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="deliveryCustPat" required readonly={readOnly} label={intl.formatMessage({ id: 'deliveryCustPat' })} />
        <CodeItem field="custDelayPat" required readonly={readOnly} label={intl.formatMessage({ id: 'custDelayPat' })} code={CodeCategory.AdjustmentPattern1} />
        <CodeItem field="custAdvancePat" required readonly={readOnly} label={intl.formatMessage({ id: 'custAdvancePat' })} code={CodeCategory.AdjustmentPattern2} />
        <CodeItem field="custStockFlag" readonly={readOnly} label={intl.formatMessage({ id: 'field.customerStockFlag' })} code={CodeCategory.CustomerStockFlag} />
    </Form >
}

const OrderCalculationFormView = ({ odmCustomerPartsOrder, setOdmCustomerPartsOrder, mode, orderGroupList }: MSAUF021PcUiProps) => {
    const intl = useIntl()
    const readOnly = mode === ScreenMode.VIEW
    const readOnly1 = mode === ScreenMode.VIEW || !odmCustomerPartsOrder.orderGroupId
    const orderGroupMap: [number, string][] = useMemo(() => orderGroupList.map(m => [m.orderGroupId, m.orderGroupNo]), [orderGroupList])
    const consolidationTypeMap = useMemo(() => {
        return [[ConsolidationType.INDIVIDUAL, "Individual"], [ConsolidationType.CONSOLIDATION, "Consolidation"]] as [number, string][]
    }, [])

    useEffect(() => {
        if (odmCustomerPartsOrder.consolidationType === ConsolidationType.INDIVIDUAL) {
            setOdmCustomerPartsOrder(odmCustomerPartsOrder => ({ ...odmCustomerPartsOrder, consolidationGroup: "" }))
        }
    }, [odmCustomerPartsOrder.consolidationType, setOdmCustomerPartsOrder])

    return <Form data={odmCustomerPartsOrder} setData={setOdmCustomerPartsOrder} >
        <EntryItem field="orderGroupId" required readonly={readOnly} label={intl.formatMessage({ id: 'orderGroupNo' })} entries={orderGroupMap} />
        <EntryItem field="consolidationType" required readonly={readOnly} label={intl.formatMessage({ id: 'consolidationType' })} entries={consolidationTypeMap} />
        <StringItem field="consolidationGroup" readonly={readOnly} label={intl.formatMessage({ id: 'consolidationGroup' })} />
        <CodeItem field="safetyStockUom" required readonly={readOnly1} label={intl.formatMessage({ id: 'safetyStockUom' })} code={CodeCategory.SafetyStockUom} />
        <NumberItem field="safetyStock" required readonly={readOnly1} label={intl.formatMessage({ id: 'safetyStock' })} />

        <NumberItem field="maxStock" required readonly={readOnly1} label={intl.formatMessage({ id: 'maxStock' })} />
        <NumberItem field="orderLeadtime" required readonly={readOnly1} label={intl.formatMessage({ id: 'orderLeadTime' })} />
        <CodeItem field="leadtimeLevel" required readonly={readOnly1} label={intl.formatMessage({ id: 'leadtimeMethod' })} code={CodeCategory.LeadtimeMethod} />
        <CodeItem field="calculationPattern" required readonly={readOnly1} label={intl.formatMessage({ id: 'calculationPattern' })} code={CodeCategory.CalculationPattern} />
    </Form >
}

const SubmitAction = memo((props: {
    odmCustomerParts: OdmCustomerParts,
    odmCustomerPartsControl: OdmCustomerPartsControl,
    odmCustomerPartOrder: OdmCustomerPartsOrder,
    display: StockOrderScreenMode,
    setDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    const { odmCustomerParts, odmCustomerPartsControl, odmCustomerPartOrder, display, setDialog } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)

    const saveStockManagementMasterDetail = useSaveStockManagementMasterDetail()
    const saveOrderCalculationMasterDetail = useSaveOrderCalculationMasterDetail()
    const saveStockManagementAndOrderCalculationMasterDetail = useSaveStockManagementAndOrderCalculationMasterDetail()
    const saveDetailInfo = StockOrderScreenMode.ONLYSMT === display
        ? saveStockManagementMasterDetail
        : StockOrderScreenMode.ONLYOC === display
            ? saveOrderCalculationMasterDetail
            : saveStockManagementAndOrderCalculationMasterDetail
    const url = StockOrderScreenMode.ONLYSMT === display
        ? 'post /smt-api/stockManagementMaster/saveStockManagementMasterDetail'
        : StockOrderScreenMode.ONLYOC === display
            ? 'post /smt-api/stockManagementMaster/saveOrderCalculationMasterDetail'
            : 'post /smt-api/stockManagementMaster/saveStockManagementAndOrderCalculationMasterDetail'
    const onclickSubmit = useCallback(() => {
        let alarmUsagePatternDetail: StockManagementMasterDetail = {
            customerPart: odmCustomerParts,
            customerPartControl: odmCustomerPartsControl,
            customerPartOrder: odmCustomerPartOrder,
            isConfirm: false,
        }
        const functionId = functionStore.register(() => {
            setDisabled(true)
            saveDetailInfo(alarmUsagePatternDetail, { serialized: true, silent: true }).then((result) => {
                if (result.messages === null || result.messages.length === 0) {
                    dispatch(applicationActions.pushSuccess({
                        title: intl.formatMessage({ id: url }),
                        messages: [{ code: 'notice.success' }],
                    }))
                    if (result.warningFlag === 1) {
                        setDialog(true)
                    } else {
                        if (StockOrderScreenMode.ONLYSMT === display) {
                            navigate('/stockManagementMaster')
                        } else if (StockOrderScreenMode.ONLYOC === display) {
                            navigate('/orderCalculationMaster')
                        } else {
                            navigate('/stockManagementOrderCalcMaster')
                        }
                    }
                } else {
                    let alarmUsagePatternDetailConfirm: StockManagementMasterDetail = {
                        customerPart: odmCustomerParts,
                        customerPartControl: odmCustomerPartsControl,
                        customerPartOrder: odmCustomerPartOrder,
                        isConfirm: true,
                    }
                    const functionId1 = functionStore.register(() => {
                        saveDetailInfo(alarmUsagePatternDetailConfirm, { serialized: true }).then(() => {
                            if (StockOrderScreenMode.ONLYSMT === display) {
                                navigate('/stockManagementMaster')
                            } else if (StockOrderScreenMode.ONLYOC === display) {
                                navigate('/orderCalculationMaster')
                            } else {
                                navigate('/stockManagementOrderCalcMaster')
                            }
                        })
                    })

                    dispatch(applicationActions.pushWarning({
                        title: intl.formatMessage({ id: 'confirm' }),
                        messages: result.messages,
                        actions: [{
                            label: 'CANCEL'
                        }, {
                            functionId: functionId1,
                            label: 'CONFIRM',
                        }]
                    }))
                }
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [odmCustomerParts, odmCustomerPartsControl, odmCustomerPartOrder, functionStore, dispatch, title, saveDetailInfo, intl, url, setDialog, display, navigate])

    return <>
        <SubmitCallbackViewAction access="STCK.MSAUF021.SUBMIT" callback={onclickSubmit} disabled={disabled} />
    </>
})

const WaringMessageDialog = memo((props: {
    dialog: boolean,
    setDialog: React.Dispatch<React.SetStateAction<boolean>>,
    display: StockOrderScreenMode
}) => {
    const { dialog, setDialog, display } = props
    const navigate = useNavigate()
    const onClose = useCallback(() => {
        setDialog(false)
        if (StockOrderScreenMode.ONLYSMT === display) {
            navigate('/stockManagementMaster')
        } else if (StockOrderScreenMode.ONLYOC === display) {
            navigate('/orderCalculationMaster')
        } else {
            navigate('/stockManagementOrderCalcMaster')
        }
    }, [display, navigate, setDialog])
    return <Dialog open={dialog} onClose={onClose} maxWidth="xl" style={{ flex: 1 }} BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}>
        <DialogTitle style={{ textAlign: 'center' }}><Typography variant="h3"><FormattedMessage id="warningMessageTitle" /></Typography></DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
            <DialogContentText>
                <FormattedMessage id="warningMessageContent2" />
            </DialogContentText>
            <Button
                variant={'outlined'}
                color="secondary"
                onClick={onClose}
                style={{
                    width: '30%',
                }}>
                <FormattedMessage id="ok" />
            </Button>
        </DialogContent>
    </Dialog>
})
