import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, PaginationLayout, Row, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import { useIntl } from "react-intl"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { dateToJson } from "../../../services/utils/serializer"
import { useOCCLS013Selector } from "./OCCLS013Slice"

export function CustUsageBySellerTable() {

    const intl = useIntl()
    const columns = [
        { field: 'buyer', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 200 },
        { field: 'usageVersion', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.usageVersion' }), width: 200 },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 300 },
        { field: 'uploadedDatetime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'uploadedDatetime' }), width: 300 },
        { field: 'partsNum', dataTypeName: 'number', title: intl.formatMessage({ id: 'partsNum' }), width: 150 },
    ]

    const customerUsages = useOCCLS013Selector(state => {
        const uploadedDate = dateToJson(state.custUsageDate)
        if (uploadedDate) {
            return state.customerUsages.filter(cu => cu.uploadedDate === uploadedDate)
        } else {
            return state.customerUsages
        }
    }, (prev, curr) => {
        return prev === curr
            || (prev.length === curr.length && prev.every((cu, index) => cu === curr[index]))
    })

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <PercentTypeProvider />
        <Data rows={customerUsages} columns={columns} getRowId={(row: Row) => row.usageVersion} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
    </DataGrid>
}