import { Tooltip } from "@material-ui/core"
import { NoteAddOutlined } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { ListTableRow } from "./ListTable"
import { occls010Actions, OrderCalculationType } from "./OCCLS010Slice"

export interface ReviewRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}

export function ReviewRowAction(props: ReviewRowActionProps) {
  const { tableRow, type } = props
  const row = tableRow.row as ListTableRow
  const seller = row.sellerCode !== null && row.sellerCode !== undefined
  const buyer = row.buyerCode !== null && row.buyerCode !== undefined

  const data = {
    orderGroupId: row.orderGroupId,
    orderTime: row.orderMonth!,
  }

  const dispatch = useDispatch()
  //TODO
  const display = (row.status === OrderCalcStatus.ORDER_ISSUED || type === 'Ehm') && row.orderVersion === 1
  const navigate = useNavigate()
  if (!display) {
    return null
  } else {
    return <Access access="STCK.OCCLS010.REVIEW">
      <Tooltip title={<FormattedMessage id="review" />}>
        <IconButton onClick={() => {
          if (type === 'PNA') {
            dispatch(occls010Actions.reviewPNA({
              data,
              callback: (orderCalcNo) => navigate(`/orderCalculationPNA/editSpot-${encodeURIComponent(orderCalcNo)}`)
            }))
          } else if (type === 'Ehm' && buyer) {
            dispatch(occls010Actions.reviewSMT({
              data,
              callback: (orderCalcNo) => navigate(`/orderCalculationEhm/editSpotForBuyer-${encodeURIComponent(orderCalcNo)}`)
            }))
          } else if (type === 'Ehm' && seller) {
            dispatch(occls010Actions.reviewSMT({
              data,
              callback: (orderCalcNo) => navigate(`/orderCalculationEhm/editSpotForSeller-${encodeURIComponent(orderCalcNo)}`)
            }))
          } else {
            dispatch(occls010Actions.review({
              data,
              callback: (orderCalcNo) => navigate(`/orderCalculation/editSpot-${encodeURIComponent(orderCalcNo)}`)
            }))
          }
        }}>
          <NoteAddOutlined />
        </IconButton>
      </Tooltip>
    </Access >
  }
}