import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Message } from "@rithe/form"
import { useSelector } from "react-redux"
import appConfig from "../../../configs/appConfig"
import { changeCustomerUsageDate, getCustomerUsageVersionBySeller, orderCalculationCreateView, orderCalculationEhmGetBuyerOptions, orderCalculationEhmGetSellerOptions, orderCalculationGetBuyerOptions, orderCalculationGetCustomerOptions, orderCalculationGetSellerOptions, runOrderCalculation, runOrderCalculationEhm, runOrderCalculationPNA } from "../../../services/smt/api/OrderCalculationApi"
import { OrderCalcRunConditionVo } from "../../../services/smt/models/OrderCalcRunConditionVo"
import { OrderCalculationCreateViewCondition } from "../../../services/smt/models/OrderCalculationCreateViewCondition"
import { OrderCalculationCreateViewResult } from "../../../services/smt/models/OrderCalculationCreateViewResult"
import { OrderCalcVo } from "../../../services/smt/models/OrderCalcVo"
import { OrderGroupVo } from "../../../services/smt/models/OrderGroupVo"
import { SdtSmtCustUsage } from "../../../services/smt/models/SdtSmtCustUsage"
import errorToNotification from "../../../utils/axios/errorToNotification"
import responseToNotification from "../../../utils/axios/responseToNotification"
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"
import { applicationActions } from "../../Application/applicationSlice"

export type OrderCalculationType = 'default' | 'PNA' | 'Ehm' | 'Cust'

export const occls013Slice = createSlice({
  name: 'occls013',
  initialState: {
    type: 'default' as OrderCalculationType,
    orderTime: undefined as Date | undefined,
    cutoffDate: undefined as Date | undefined,
    custUsageDate: undefined as Date | undefined,
    customerOptions: {} as Record<number, string>,
    buyerOptions: {} as Record<string, string>,
    sellerOptions: {} as Record<string, string>,
    orderGroup: undefined as OrderGroupVo | undefined,
    orderCalc: undefined as OrderCalcVo | undefined,
    customerUsages: [] as SdtSmtCustUsage[],
    formMessages: [] as Message[],
    selections: [] as string[],
  },
  reducers: {
    setType: (state, action: PayloadAction<OrderCalculationType>) => {
      state.type = action.payload
    },
    setOrderTime: (state, action: PayloadAction<Date | undefined>) => {
      state.orderTime = action.payload
    },
    setCutoffDate: (state, action: PayloadAction<Date | undefined>) => {
      state.cutoffDate = action.payload
    },
    setCustUsageDate: (state, action: PayloadAction<Date | undefined>) => {
      state.custUsageDate = action.payload
    },
    setCustomerOptions: (state, action: PayloadAction<Record<number, string>>) => {
      state.customerOptions = action.payload
    },
    setBuyerOptions: (state, action: PayloadAction<Record<string, string>>) => {
      state.buyerOptions = action.payload
    },
    setSellerOptions: (state, action: PayloadAction<Record<string, string>>) => {
        state.sellerOptions = action.payload
    },
    setData: (state, action: PayloadAction<OrderCalculationCreateViewResult>) => {
      state.orderGroup = action.payload.orderGroup
      state.orderCalc = action.payload.orderCalc
    },
    setCustomerUsages: (state, action: PayloadAction<SdtSmtCustUsage[]>) => {
      state.customerUsages = action.payload
    },
    setFormMessages: (state, action: PayloadAction<Message[]>) => {
      state.formMessages = action.payload
    },
    setSelections: (state, action: PayloadAction<string[]>) => {
      state.selections = action.payload.slice(-1)
    },
    unmount: (state) => {
      state.customerOptions = {}
      state.buyerOptions = {}
      state.orderGroup = undefined
      state.orderCalc = undefined
      state.customerUsages = []
      state.selections = []
    },
  }
})

const getCustomerOptions = createAsyncThunk('occls013/getCustomerOptions', (_, thunk) => {
  return orderCalculationGetCustomerOptions(thunkAxiosInstance(thunk))
    .then(response => {
      thunk.dispatch(occls013Actions.setCustomerOptions(response.data))
    })
    .catch(error => {
      thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
})

const getBuyerOptions = createAsyncThunk('occls013/getBuyerOptions', (_, thunk) => {
  return orderCalculationGetBuyerOptions(thunkAxiosInstance(thunk))
    .then(response => {
      thunk.dispatch(occls013Actions.setBuyerOptions(response.data))
    })
    .catch(error => {
      thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
})


const getBuyerOptionsForEhm = createAsyncThunk('occls013/getBuyerOptionsForEhm', (_, thunk) => {
    return orderCalculationEhmGetBuyerOptions(thunkAxiosInstance(thunk))
        .then(response => {
            thunk.dispatch(occls013Actions.setBuyerOptions(response.data))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const getSellerOptionsForEhm = createAsyncThunk('occls010/getSellerOptionsForEhm', (_, thunk) => {
  return orderCalculationEhmGetSellerOptions(thunkAxiosInstance(thunk))
      .then(response => {
          thunk.dispatch(occls013Actions.setSellerOptions(response.data))
      })
      .catch(error => {
          thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
      })
})


const getSellerOptions = createAsyncThunk('occls013/getSellerOptions', (_, thunk) => {
    return orderCalculationGetSellerOptions(thunkAxiosInstance(thunk))
        .then(response => {
            thunk.dispatch(occls013Actions.setSellerOptions(response.data))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const createView = createAsyncThunk('occls013/createView', (args: OrderCalculationCreateViewCondition, thunk) => {
  return orderCalculationCreateView(thunkAxiosInstance(thunk), args)
    .then(response => {
      thunk.dispatch(occls013Actions.setData(response.data))
      const orderGroup = response.data.orderGroup
      const orderCalc = response.data.orderCalc
      // overwrite orderTime
      if (orderCalc && orderCalc.orderStartDate) {
        thunk.dispatch(occls013Actions.setOrderTime(orderCalc.orderStartDate))
      }
      // overwrite cutoffDate
      if (orderCalc && orderCalc.cutoffDate) {
        thunk.dispatch(occls013Actions.setCutoffDate(orderCalc.cutoffDate))
      }
      // overwrite custUsageDate
      if (orderCalc && orderCalc.custUsageDate) {
        thunk.dispatch(occls013Actions.setCustUsageDate(orderCalc.custUsageDate))
      }
      thunk.dispatch(occls013Actions.getCustomerUsages({
        orderGroupId: orderGroup.orderGroupId,
        // custUsageDate: dateToJson(orderCalc?.custUsageDate), no need
      }))
    })
    .catch(error => {
      thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
})


const createViewBySeller = createAsyncThunk('occls013/createView', (args: OrderCalculationCreateViewCondition, thunk) => {
  return orderCalculationCreateView(thunkAxiosInstance(thunk), args)
    .then(response => {
      thunk.dispatch(occls013Actions.setData(response.data))
      const orderGroup = response.data.orderGroup
      const orderCalc = response.data.orderCalc
      // overwrite orderTime
      if (orderCalc && orderCalc.orderStartDate) {
        thunk.dispatch(occls013Actions.setOrderTime(orderCalc.orderStartDate))
      }
      // overwrite cutoffDate
      if (orderCalc && orderCalc.cutoffDate) {
        thunk.dispatch(occls013Actions.setCutoffDate(orderCalc.cutoffDate))
      }
      // overwrite custUsageDate
      if (orderCalc && orderCalc.custUsageDate) {
        thunk.dispatch(occls013Actions.setCustUsageDate(orderCalc.custUsageDate))
      }
      thunk.dispatch(occls013Actions.getCustomerUsagesBySeller({
        orderGroupId: orderGroup.orderGroupId,
      }))
    })
    .catch(error => {
      thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
})

const getCustomerUsages = createAsyncThunk('occls013/getCustomerUsages', (args: { orderGroupId?: string, custUsageDate?: string }, thunk) => {
  thunk.dispatch(applicationActions.addMask())
  return changeCustomerUsageDate(thunkAxiosInstance(thunk), args)
    .then(response => {
      thunk.dispatch(occls013Actions.setCustomerUsages(response.data))
    })
    .catch(error => {
      thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
    .finally(() => thunk.dispatch(applicationActions.removeMask()))
})

const getCustomerUsagesBySeller = createAsyncThunk('occls013/getCustomerUsagesBySeller', (args: { orderGroupId?: string }, thunk) => {
  thunk.dispatch(applicationActions.addMask())
  return getCustomerUsageVersionBySeller(thunkAxiosInstance(thunk), args)
    .then(response => {
      thunk.dispatch(occls013Actions.setCustomerUsages(response.data))
      if (response.data) {
        thunk.dispatch(occls013Actions.setSelections(response.data.map(m => m.usageVersion!)))
      }
    })
    .catch(error => {
      thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
    .finally(() => thunk.dispatch(applicationActions.removeMask()))
})

const calculate = createAsyncThunk('occls013/calculate', (args: { data: OrderCalcRunConditionVo, callback: () => void }, thunk) => {
  const { data, callback } = args
  thunk.dispatch(applicationActions.addMask())
  return runOrderCalculation(thunkAxiosInstance(thunk), data)
    .then(response => {
      thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
      callback()
    })
    .catch(error => {
      thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
    .finally(() => thunk.dispatch(applicationActions.removeMask()))
})

const calculatePNA = createAsyncThunk('occls013/calculatePNA', (args: { data: OrderCalcRunConditionVo, callback: () => void }, thunk) => {
  const { data, callback } = args
  thunk.dispatch(applicationActions.addMask())
  return runOrderCalculationPNA(thunkAxiosInstance(thunk), data)
    .then(response => {
      thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
      callback()
    })
    .catch(error => {
      thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
    .finally(() => thunk.dispatch(applicationActions.removeMask()))
})

const calculateSMT = createAsyncThunk('occls013/calculateSMT', (args: { data: OrderCalcRunConditionVo, callback: () => void }, thunk) => {
  const { data, callback } = args
  thunk.dispatch(applicationActions.addMask())
  return runOrderCalculationEhm(thunkAxiosInstance(thunk), data)
    .then(response => {
      thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
      callback()
    })
    .catch(error => {
      thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
    .finally(() => thunk.dispatch(applicationActions.removeMask()))
})

export const occls013Actions = {
  ...occls013Slice.actions,
  getCustomerOptions,
  getBuyerOptions,
  getBuyerOptionsForEhm,
  getSellerOptions,
  getSellerOptionsForEhm,
  createView,
  createViewBySeller,
  getCustomerUsages,
  getCustomerUsagesBySeller,
  calculate,
  calculatePNA,
  calculateSMT
}

const occls013Reducer = occls013Slice.reducer
type State = ReturnType<typeof occls013Reducer>
function selectOCCLS013State(state: any): State {
  return state[appConfig.appFullName][occls013Slice.name]
}

export function useOCCLS013Selector<R>(selector: (state: State) => R, equalityFn?: (left: R, right: R) => boolean) {
  return useSelector<any, R>(state => selector(selectOCCLS013State(state)), equalityFn)
}