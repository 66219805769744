import { Project } from "../../../utils/Project"

export type OrderGroupPeopleInfoResult = Required & Partial<Optional>
type OrderGroupPeopleInfoResultJson = JsonRequired & Partial<JsonOptional>
export const orderGroupPeopleInfoResultFromJson: Project<OrderGroupPeopleInfoResultJson, OrderGroupPeopleInfoResult> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    sellerList: UidCode[],
    buyerList: UidCode[],
}

interface UidCode {
    uid: string,
    code: string
}